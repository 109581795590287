/**
 * Presentational component for the trends scatter plot.
 */

import { useState, useEffect, Fragment } from 'react';
import {
  ScatterChart,
  Scatter,
  XAxis,
  YAxis,
  ZAxis,
  Tooltip,
  type TooltipProps,
  ResponsiveContainer,
  ReferenceLine,
} from 'recharts';
import { formatSeconds } from '@/utils/time';
import { type Average } from '@/types/Average';
import { type ScatterData } from '@/types/ScatterData';
import { TrendLineChart } from './TrendLineChart';
import { DayPartsChart } from './DayPartsChart';
import { RegionsGraph } from './RegionsGraph';
import { useData } from '@/hooks/useData';
import { colors, mutedColors } from '@/utils/Utils';
import { type DayPartData } from '@/types/DayPartData';
import { type RegionsStats } from '@/types/RegionsStats';
import DropdownAnalysisLocationMultiple from './../../dropdowns/DropdownAnalysisLocationMultiple';

export default function ScatterPlot({
  trendsData,
  dayPartsData,
  regionsData,
}: {
  trendsData: Average[];
  dayPartsData: DayPartData[];
  regionsData: RegionsStats[];
}): JSX.Element {
  const [chartData, setChartData] = useState<ScatterData[]>([]);
  const [trendLineData, setTrendLineData] = useState<Average[] | null>(null);
  const [dayPartData, setDayPartData] = useState<DayPartData[] | null>(null);
  const [regionData, setRegionData] = useState<RegionsStats[] | null>(null);
  const [selectedColors, setSelectedColors] = useState<Record<string, string>>(
    {}
  );
  const {
    selectedFranchise,
    selectedDates,
    selectedLocations,
    selectedAnalysisLocations,
    setSelectedAnalysisLocations,
    handleSelectedAnalysisLocationsChange,
  } = useData();
  const [prevLocations, setPrevLocations] = useState<any[]>([]);

  const clearState = () => {
    setTrendLineData(null);
    setDayPartData(null);
    setRegionData(null);
    setSelectedAnalysisLocations([]);
    setSelectedColors({});
  };

  useEffect(() => {
    // clear charts on franchise switch
    clearState();
  }, [selectedFranchise, selectedDates, selectedLocations]);

  useEffect(() => {
    if (selectedAnalysisLocations.length > 0) {
      let entry: ScatterData | undefined;
      const entries: ScatterData[] = [];
      if (selectedAnalysisLocations.length < prevLocations.length) {
        // Removed a location
        const removedLocations = prevLocations.filter(
          (loc) => !selectedAnalysisLocations.includes(loc)
        );
        removedLocations.forEach((removedLocation) => {
          entry = chartData.find((entry) => entry.id === removedLocation.id);
          entry && entries.push(entry);
        });
      } else {
        // Added a location
        let newlyAdded = selectedAnalysisLocations.filter(
          (loc) => !prevLocations.includes(loc)
        );
        if (newlyAdded.length === 0) {
          // SJK 12/20/2024: to account for group setting race condition
          newlyAdded = selectedAnalysisLocations;
        }

        newlyAdded.forEach((loc) => {
          entry = chartData.find((entry) => entry.id === loc.id);
          entry && entries.push(entry);
        });
      }
      // Update state with added or removed entry
      entries.length > 0 &&
        entries.forEach((entry) => {
          updateAllState(entry);
        });
    } else if (prevLocations.length > 0) {
      // Removed the final location
      clearState();
    }
    // Set length for next time
    setPrevLocations(selectedAnalysisLocations);
  }, [selectedAnalysisLocations, chartData]);

  useEffect(() => {
    setChartData(getChartData(trendsData));
  }, [trendsData]);

  // Convert average data to scatter plot data
  function getChartData(rawData: Average[]): ScatterData[] {
    const mappedRevenues = mapToRange(
      rawData.map((location) => location.averageRevenue)
    );

    const data = rawData.map((location, index) => {
      const lastTrend = location.trendData.reduce((a, b) =>
        a.date > b.date ? a : b
      );
      const allVolumes =
        location.trendData.reduce((acc, loc) => acc + loc.averageVolume, 0) /
        location.trendData.length;
      const allTimes =
        location.trendData.reduce(
          (acc, loc) => acc + loc.averageServiceTime,
          0
        ) / location.trendData.length;
      return {
        volume: allVolumes,
        serviceTime: allTimes,
        id: location.locationId,
        name: location.locationName,
        date: lastTrend.date, // Use last date, even though this is an average
        color:
          location.averageRevenue === 0
            ? mutedColors[index % mutedColors.length]
            : colors[index % colors.length],
        avgRevenue: location.averageRevenue,
        avgRevenueSize: mappedRevenues[index],
      };
    });
    return data;
  }

  // function to map sizes for the range of
  function mapToRange(
    numbers: number[],
    minTarget: number = 50,
    maxTarget: number = 1250
  ): number[] {
    const filteredNumbers = numbers.filter((num) => num !== 0); // Filter out 0
    const minSource = Math.min(...filteredNumbers);
    const maxSource = Math.max(...filteredNumbers);

    // Create a sorted array of unique values from filteredNumbers
    const uniqueSortedNumbers = Array.from(new Set(filteredNumbers)).sort(
      (a, b) => a - b
    );

    return numbers.map((num) => {
      // Check if num is outside the source range
      if (num < minSource) return minTarget;
      if (num > maxSource) return maxTarget;

      // Find the index of the current number in the unique sorted array
      const index = uniqueSortedNumbers.findIndex((value) => value >= num);

      // Calculate the even mapping
      const mappedValue =
        (index / (uniqueSortedNumbers.length - 1)) * (maxTarget - minTarget) +
        minTarget;

      return mappedValue;
    });
  }

  const updateAllState = (entry: ScatterData) => {
    setSelectedColors((prevMap) => ({
      ...prevMap,
      [entry.id]: entry.color,
    }));

    const selectedTrendsData = trendsData.find(
      (data) => data.locationName === entry.name
    );
    const selectedDayPartData = dayPartsData.find(
      (data) => data.locationName === entry.name
    );
    const selectedRegionData = regionsData.find(
      (data) => data.locationName === entry.name
    );

    if (selectedTrendsData) {
      setTrendLineData((prevData) => {
        if (prevData) {
          if (
            prevData
              .map((tl) => tl.locationId)
              .includes(selectedTrendsData.locationId)
          ) {
            return prevData.filter(
              (tl) => tl.locationId !== selectedTrendsData.locationId
            );
          } else {
            return [...prevData, selectedTrendsData];
          }
        }
        return [selectedTrendsData];
      });
    }

    if (selectedDayPartData) {
      setDayPartData((prevData) => {
        if (prevData) {
          if (
            prevData
              .map((dp) => dp.locationId)
              .includes(selectedDayPartData.locationId)
          ) {
            return prevData.filter(
              (dp) => dp.locationId !== selectedDayPartData.locationId
            );
          } else {
            return [...prevData, selectedDayPartData];
          }
        }
        return [selectedDayPartData];
      });
    }

    if (selectedRegionData) {
      setRegionData((prevData) => {
        if (prevData) {
          if (
            prevData
              .map((r) => r.locationId)
              .includes(selectedRegionData.locationId)
          ) {
            return prevData.filter(
              (r) => r.locationId !== selectedRegionData.locationId
            );
          } else {
            return [...prevData, selectedRegionData];
          }
        }
        return [selectedRegionData];
      });
    }
  };

  // Add newly clicked data to the selectedAnalysisLocations
  const handlePointClick = (entry: ScatterData) => {
    handleSelectedAnalysisLocationsChange(entry.id);
  };

  const CustomTooltip: React.FC<TooltipProps<any, any>> = ({
    active,
    payload,
  }) => {
    if (active && payload?.length) {
      const { name, volume, serviceTime, avgRevenue, color } =
        payload[0].payload;
      return (
        <div
          style={{
            backgroundColor: '#fff',
            border: '1px solid #ccc',
            padding: '5px',
            borderRadius: '4px',
            fontSize: 'small',
          }}
        >
          <p>
            <span
              style={{
                width: '1em',
                height: '1em',
                backgroundColor: color,
                marginRight: '0.5em',
                display: 'inline-block',
              }}
            />
            {`${name}`}
          </p>
          <p>{`Volume: ${volume.toFixed(2)}`}</p>
          <p>{`Service Time: ${formatSeconds(serviceTime)}`}</p>
          <p>
            {avgRevenue === 0
              ? 'NO REVENUE DATA'
              : `Daily Revenue: $${avgRevenue.toFixed(2)}`}
          </p>
        </div>
      );
    }

    return null;
  };

  const averageX =
    chartData.reduce((sum, d) => sum + d.serviceTime, 0) / chartData.length;
  const averageY =
    chartData.reduce((sum, d) => sum + d.volume, 0) / chartData.length;
  const maxDiffX = chartData.reduce((maxDiff, d) => {
    const diff = Math.abs(d.serviceTime - averageX);
    return Math.max(maxDiff, diff);
  }, 0);
  const maxDiffY = chartData.reduce((maxDiff, d) => {
    const diff = Math.abs(d.volume - averageY);
    return Math.max(maxDiff, diff);
  }, 0);

  const height = 595;

  return (
    <div>
      <div className="flex flex-col col-span-full sm:col-span-6 bg-white dark:bg-slate-800 shadow-lg rounded-sm border border-slate-200 dark:border-slate-700">
        <header className="px-5 py-4 border-b border-slate-100 dark:border-slate-700 flex justify-between">
          <div>
            <h2 className="font-semibold text-slate-800 dark:text-slate-100">
              Store Performance
            </h2>
            <h5 className="text-slate-600 dark:text-slate-100">
              Service Time and Customer Count
            </h5>
          </div>
        </header>
        <ResponsiveContainer className="grow" height={height}>
          <ScatterChart
            margin={{
              top: 20,
              right: 20,
              bottom: 20,
              left: 20,
            }}
          >
            <XAxis
              type="number"
              dataKey="serviceTime"
              name="serviceTime"
              tickCount={7}
              tick={{ fontSize: 12, fill: '#808080' }}
              tickFormatter={(value) => formatSeconds(value)}
              domain={[averageX - maxDiffX - 10, averageX + maxDiffX + 10]}
            />
            <YAxis
              type="number"
              dataKey="volume"
              name="volume"
              tickCount={7}
              tick={{ fontSize: 12, fill: '#808080' }}
              tickFormatter={(value) => `${value.toFixed(0)} count`}
              domain={[averageY - maxDiffY - 50, averageY + maxDiffY + 50]}
            />
            <ZAxis
              type="number"
              dataKey="avgRevenueSize"
              range={[10, 1250]}
              name="Daily Revenue Avg"
            />
            <Tooltip content={<CustomTooltip />} cursor={false} />
            {chartData?.map((entry, index) => (
              <Scatter
                key={entry.name}
                name={entry.name}
                data={[entry]}
                fill={
                  entry.avgRevenue === 0
                    ? mutedColors[index % mutedColors.length]
                    : colors[index % colors.length]
                }
                onClick={() => {
                  handlePointClick(entry);
                }}
              />
            ))}
            <ReferenceLine x={averageX} stroke="#D3D3D3" />
            <ReferenceLine y={averageY} stroke="#D3D3D3" />
          </ScatterChart>
        </ResponsiveContainer>
        <footer className="px-4 py-4 dark:border-slate-700">
          <h5 className="text-sm text-slate-600 dark:text-slate-100">
            The further the points get to the right, the slower the service
            time. <br />
            The further the points get to the top, the higher the customer
            count.
          </h5>
        </footer>
      </div>
      <div className="sm:flex sm:justify-left sm:items-center mb-4">
        {/* Left: Title */}
        <div className="mt-4 mb-4 sm:mb-0">
          <h3 className="text-xl text-slate-700 dark:text-slate-100">
            Detailed View 🔎
          </h3>
        </div>
        <div className="sm:flex sm:justify-between sm:items-center m-4 gap-4">
          <DropdownAnalysisLocationMultiple />
        </div>
      </div>
      <div className="flex flex-wrap bg-white dark:bg-slate-800 shadow-lg rounded-sm border border-slate-200 dark:border-slate-700">
        <TrendLineChart
          averageData={trendLineData}
          chartType="volume"
          colorMap={selectedColors}
        />
        <TrendLineChart
          averageData={trendLineData}
          chartType="serviceTime"
          colorMap={selectedColors}
        />
        <DayPartsChart dayPartsData={dayPartData} colorMap={selectedColors} />
        <RegionsGraph regionsData={regionData} colorMap={selectedColors} />
      </div>
    </div>
  );
}
