import Flatpickr from 'react-flatpickr';
import { useEffect, useState } from 'react';
import DateChangeButton from '../buttons/DateChangeButton';
import { useData } from '../../hooks/useData';

function DatepickerWithDefaults({
  initDates,
  handleDateChange,
  mode = 'range',
}: {
  initDates: Date[];
  handleDateChange: (selectedDates: Date[]) => void;
  mode?: 'single' | 'multiple' | 'range' | 'time';
}): JSX.Element {
  const today = new Date();
  const yesterday = new Date();
  const oneWeekAgo = new Date();
  const oneMonthAgo = new Date();
  yesterday.setDate(yesterday.getDate() - 1);
  oneWeekAgo.setDate(today.getDate() - 7);
  oneMonthAgo.setDate(today.getDate() - 30);

  const [selectedDates, setSelectedDates] = useState<Date[]>(initDates);
  const [selectedButton, setSelectedButton] = useState<string | null>(null); // Track the selected button
  const [isManuallyChanged, setIsManuallyChanged] = useState(false); // Flag to track if the date was manually changed

  function setLastWeek(): void {
    setSelectedDates([oneWeekAgo, today]);
    handleDateChange([oneWeekAgo, today]);
    setSelectedButton('lastWeek'); // Set selected button to "lastWeek"
    setIsManuallyChanged(true); // Mark as manually changed
  }

  function setLastMonth(): void {
    setSelectedDates([oneMonthAgo, today]);
    handleDateChange([oneMonthAgo, today]);
    setSelectedButton('lastMonth'); // Set selected button to "lastMonth"
    setIsManuallyChanged(true); // Mark as manually changed
  }

  function setToday(): void {
    setSelectedDates([yesterday, today]);
    handleDateChange([yesterday, today]);
    setSelectedButton('today'); // Set selected button to "today"
    setIsManuallyChanged(true); // Mark as manually changed
  }

  // UseEffect to reset selectedButton when selectedDates change and were not changed by the manual set functions
  useEffect(() => {
    if (!isManuallyChanged) {
      setSelectedButton(null); // Reset selectedButton if selectedDates were changed by something other than the manual functions
    }
    setIsManuallyChanged(false); // Reset the flag
  }, [selectedDates]);

  return (
    <div className="relative" data-cy="datepicker">
      <Flatpickr
        value={initDates}
        className="form-input pl-9 dark:bg-slate-800 text-slate-500 hover:text-slate-600 dark:text-slate-300 dark:hover:text-slate-200 font-medium w-[15.5rem]"
        onChange={(dates: Date[]) => {
          const shouldChange = mode === 'single' || dates.length > 1;
          if (shouldChange) {
            setSelectedDates(dates);
            handleDateChange(dates);
          }
        }}
        options={{
          mode,
          dateFormat: 'M j, Y',
          minDate: new Date('2020-01-01'),
          maxDate: new Date(),
        }}
      />
      <div className="absolute inset-0 right-auto flex items-center pointer-events-none">
        <svg
          className="w-4 h-4 fill-current text-slate-500 dark:text-slate-400 ml-3"
          viewBox="0 0 16 16"
        >
          <path d="M15 2h-2V0h-2v2H9V0H7v2H5V0H3v2H1a1 1 0 00-1 1v12a1 1 0 001 1h14a1 1 0 001-1V3a1 1 0 00-1-1zm-1 12H2V6h12v8z" />
        </svg>
      </div>

      {/* Buttons for date selection */}
      <DateChangeButton
        handleDateChange={setToday}
        text={'Yesterday'}
        isSelected={selectedButton === 'today'} // Set selected state for "Yesterday"
      />

      {mode !== 'single' && (
        <>
          <DateChangeButton
            handleDateChange={setLastWeek}
            text={'7 days'}
            isSelected={selectedButton === 'lastWeek'} // Set selected state for "7 days"
          />
          <DateChangeButton
            handleDateChange={setLastMonth}
            text={'30 days'}
            isSelected={selectedButton === 'lastMonth'} // Set selected state for "30 days"
          />
        </>
      )}
    </div>
  );
}

export default DatepickerWithDefaults;
