import { presentationFormat } from '@/lib/utils';
import Select from 'react-select';

export default function FilterModal({
    filterOptions,
    selectedFilters, 
    setSelectedFilters,
    selectedFlags,
    setSelectedFlags,
    flagOptions,
    onApply 
}: {
    filterOptions: Record<string, string[]>;
    selectedFilters: Record<string, string[]>;
    setSelectedFilters: React.Dispatch<React.SetStateAction<Record<string, string[]>>>;
    selectedFlags: string[];
    setSelectedFlags: React.Dispatch<React.SetStateAction<string[]>>;
    flagOptions: string[];
    onApply: () => void;
}): JSX.Element {

  const flagOptionsObject = flagOptions.map((flag) => ({
    label: presentationFormat(flag),
    value: flag,
  }));

  const handleSelectionChange = (column: string, selectedOptions: any) => {
    const selectedValues = selectedOptions ? selectedOptions.map((option: any) => option.value) : [];
    setSelectedFilters((prev) => ({
      ...prev,
      [column]: selectedValues,
    }));
  };

  const handleFlagSelectionChange = (selectedOptions: any) => {
    const selectedFlags = selectedOptions ? selectedOptions.map((option: any) => option.value) : [];
    setSelectedFlags(selectedFlags);
  };


  return (
    <div className="flex flex-col space-y-3 my-2" style={{ maxHeight: '300px', overflowY: 'scroll' }}>
      {Object.keys(filterOptions).map((column) => (
        <div className="mx-8" key={column}>
          <h4>{presentationFormat(column)}</h4>
          
          <Select
            isMulti
            name={column}
            options={filterOptions[column].map(option => ({ label: option, value: option }))}
            value={filterOptions[column].filter(option => selectedFilters[column]?.includes(option))
                                        .map(option => ({ label: option, value: option }))}
            onChange={(selectedOptions) => handleSelectionChange(column, selectedOptions)}
            className="basic-multi-select"
            classNamePrefix="select"
          />
        </div>
      ))}

      {/* Flag options */}
      <div className="mx-8">
        <h4>Flag</h4>
        <Select
          isMulti
          name="flags"
          options={flagOptionsObject}
          value={flagOptionsObject.filter(option => selectedFlags.includes(option.value))}
          onChange={handleFlagSelectionChange}
          className="basic-multi-select"
          classNamePrefix="select"
        />
      </div>

      
      <button
        className="button bg-transparent hover:bg-blue-400 text-blue-400 hover:text-white rounded-full border border-blue-400 py-1 px-2 m-9 text-xs min-w-20 self-center"
        style={{ maxHeight: '2.5em' }}
        onClick={onApply}
      >
        Apply
      </button>
    </div>
  );
};