
// import { useState, useEffect } from "react";

// import { ImageData } from "../../interfaces/imageData";
// import { DataArea } from "../../interfaces/dataArea";
// import { Labelme } from "../../interfaces/labelme";
// import { Size } from "../../interfaces/size";

// import Loader from "../../labelmeComponents/Loader/loader";
// import Displayer from "../../labelmeComponents/Displayer/displayer";
// import Canvas from "../../labelmeComponents/Canvas/canvas";
// import Box from "../../labelmeComponents/Box/box";
// import Exporter from "../../labelmeComponents/Exporter/exporter";
// import { ReservedKeyword } from "../../interfaces/reservedKeyword";
// import { overrideDefaultConfigWithReservedKeywordConfig } from "../../utils/draw";
// import CollapseBox from "../../labelmeComponents/CollapseBox/collapseBox";
// import { getLabelmeImages } from '../../utils/api';
// import DropdownFranchise from "../../components/dropdowns/DropdownFranchise";
// import DropdownLocationSingle from "../../components/dropdowns/DropdownLocationSingle";
// import { useData } from "../../hooks/useData";
// import '../../css/labelme.css';

// const MainPage = () => {
//     const reservedKeywords: ReservedKeyword = {
//         "blackout": {
//             polygonColor: "rgba(0,0,0,1)",
//             description: "Reserved keyword that blacks out the polygon. Color cannot be modified"
//         },
//         "ginger": {
//             polygonColor: "rgba(235, 47, 6, 1.0)",
//             description: "Reserved keyword that sets the color to an intense tomato red. Color cannot be modified"
//         }
//     };

//     const {
//         selectedFranchise,
//         selectedLocation
//     } = useData();


//     const [canvasSize, setCanvasSize] = useState<Size<number> | undefined>(undefined);
//     const [imageData, setImageData] = useState<ImageData | undefined>(undefined);
//     const [dataAreas, setDataAreas] = useState<Array<DataArea> | undefined>([]);
//     const [editedDataArea, setEditedDataArea] = useState<DataArea | undefined>(undefined);
//     const [dataToExport, setDataToExport] = useState<Labelme | undefined>(undefined);
//     const [mode, setMode] = useState<string | undefined>(undefined);
//     const [fileName, setFileName] = useState<string>("enter");
//     const [loadedImages, setLoadedImages] = useState<Map<string, string>>(new Map());

//     const fetchImages = async (franchiseId: number, locationId: number) => {
//         const images = await getLabelmeImages(franchiseId, locationId);
//         setLoadedImages(images);
//     };

//     useEffect(() => {
//         if (selectedFranchise && selectedLocation){
//             fetchImages(selectedFranchise['id'] as unknown as number, selectedLocation['id'] as unknown as number);
//         }
//     }, [selectedLocation]);

//     // useEffect(() => {
//     //     if (selectedFranchise !== undefined && selectedLocation !== undefined) {
//     //         fetchImages(selectedFranchise, selectedLocation);
//     //     }
//     // }, [selectedLocation]);


//     // const handleFranchiseClick = (franchiseId: number) => {
//     //     setCurrentFranchise(franchiseId);
//     //     setCurrentLocation(selectedLocation); // Reset the location when a new franchise is selected
//     // };

//     // const handleLocationClick = (locationId: number) => {
//     //     setCurrentLocation(locationId);
//     // };

//     // const fetchImages = async (franchiseId: any, locationId: any) => {
//     //     let fid;
//     //     let lid;
//     //     if(franchiseId){
//     //         fid = franchiseId.id as number
//     //     } else {fid = 29}
//     //     if(locationId){
//     //         lid = locationId.id as number
//     //     } else {lid = 417}
//     //     console.log('kkkkkk', fid, lid)
//     //     const images = await getLabelmeImages(fid, lid);
//     //     setLoadedImages(images);
//     // };

//     const handleCanvasSize = (canvasSize: Size<number> | undefined) => {
//         setCanvasSize(canvasSize);
//     };

//     const handleLoadImage = (imageData: ImageData | undefined) => {
//         setImageData(imageData);
//         if (imageData === undefined) {
//             setDataAreas([]);
//         }
//     };

//     const handleDataAreas = (dataAreas: Array<DataArea> | undefined) => {
//         setDataAreas(dataAreas);
//     };

//     const handleMode = (mode: string) => {
//         setMode(mode);
//     };

//     const updateDataAreas = (dataArea: DataArea) => {
//         if (dataAreas !== undefined && dataAreas.length > 0) {
//             dataAreas.forEach((dataArea) => overrideDefaultConfigWithReservedKeywordConfig(dataArea, reservedKeywords));
//             const idxOfTargetDataArea = dataAreas.findIndex(x => x.id === dataArea.id);
//             if (idxOfTargetDataArea !== -1) {
//                 dataAreas[idxOfTargetDataArea] = dataArea;
//                 setMode("Waiting")
//                 setEditedDataArea(undefined);
//                 setDataAreas([...dataAreas]);
//             }
//         }
//     };

//     const editDataArea = (dataArea: DataArea) => {
//         setMode(`Editing Data Area with label: ${dataArea.label}`);
//         setEditedDataArea(dataArea);
//     };

//     const deleteDataAreaFromDataAreas = (dataArea: DataArea) => {
//         if (dataAreas !== undefined && dataAreas.length > 0) {
//             setDataAreas([...dataAreas.filter((x) => x.id !== dataArea.id)]);
//         }
//     };


//     const exportDataAreas = (labelme: Labelme) => {
//         setDataToExport(labelme);
//     };

//     return (
//         <div className="cont">
//             <div className="row">
//                 <div className="col-10 col-m-10 p-2 mx-auto">
//                 </div>
//                 <div className="flex flex-wrap justify-start gap-2 col-10 col-m-10 p-2 mx-auto">
//                     {/* Franchise Selector */}
//                     <DropdownFranchise />
//                     {/* Location Selector */}
//                     <DropdownLocationSingle />
//                 </div>
//             </div>
            

//             <div className="row">
//                 <div className="col-10 col-m-10 p-2 mx-auto">
//                     <CollapseBox
//                         text="How to label?"
//                         backgroundColor="#636e72"
//                         borderColor="#2d3436"
//                     >
//                         <br />
//                         <p className="color-white font-small font-lighter">select a franchise and location from the dropdown up top (if you dont see any, refresh or contact your admin to be given locations)</p>
//                         <br />
//                         <p className="color-white font-small font-lighter">images should show up one on top of the other. inspect these images, and find the one you want to draw on.</p>
//                         <br />
//                         <p className="color-white font-small font-lighter">depending on which image you choose, choose the corresponding fileName from "enter: orderboard, exit: pickup window, pull: the final or only pull forward, pull1: the first pull forward if there are two, lobby_areas: lobby area, mobile: mobile orderingg spots</p>
//                         <br />
//                         <p className="color-white font-small font-lighter">WHICHEVER FILENAME YOU PRESS LAST WILL BE THE LOCATION IT IS SENT TO... if you pressed the wrong fileName, just delete your labels and press the correct one, but if you export and send the wrong data to aws, that wouldnt be great.</p>
//                         <br />
//                         <p className="color-white font-small font-lighter">depending on which fileName is chosen, as you draw, a different set of labels will be drawn. pull, pull1, and mobile will increment from either pull or mobile 1-n. enter will do orderboard1, preorderboard1, then 2, etc...</p>
//                         <br />
//                         <p className="color-white font-small font-lighter">you can toggle between "set blackout" and "set label" to either draw the corresponding labels or draw blackout regions</p>
//                         <br />
//                         <p className="color-white font-small font-lighter">note that when fileName is changed, "set blackout" will be changed to "set label"</p>
//                         <br />
//                         <br />
//                         <p className="color-white font-small font-lighter">If you are in draw mode, right click to erase points</p>
//                         <br />
//                         <p className="color-white font-small font-lighter">To close a shape, you must finish clicking the starting point. It will highlight in yellow when the mouse is over</p>
//                         <br />
//                         <p className="color-white font-small font-lighter">To edit the shape, once you click edit in the labels, you can go to the canvas and edit the points</p>
//                         <br />
//                         <br />
//                         <p className="color-white font-small font-lighter">once you are done drawing the labels and editing them, you can delete anything you didnt want (such as preorderboard areas in enter)</p>
//                         <br />
//                         <p className="color-white font-small font-lighter">press export. this will show you the json (the data of the file that you have created)</p>
//                         <br />
//                         <p className="color-white font-small font-lighter">if you are happy with what you have produced and are confident that the fileName is correct, press "send to aws"</p>
//                         <br />
//                         <p className="color-white font-small font-lighter">you are now free to press on the next picture you would like to work on.</p>
//                     </CollapseBox>
//                 </div>
//             </div>

//             <div className="row">
//                 <div className="col-10 col-m-10 p-2 mx-auto">
//                     <p className="color-white font-small">Operation: <b><i>{mode ?? "Waiting"}</i></b></p>
//                 </div>
//             </div>

//             <div className="row">
//                 <div className="col-10 col-m-10 p-2 mx-auto">
//                     {imageData !== undefined ? (
//                         <Canvas
//                             imageData={imageData}
//                             dataAreas={dataAreas ?? []}
//                             editedDataArea={editedDataArea}
//                             sendDataArea={handleDataAreas}
//                             handleCanvasSize={handleCanvasSize}
//                             handleMode={handleMode}
//                             fileName={fileName}
//                         />
//                     ) : (
//                         <Box text="Waiting for image" backgroundColor="rgba(116, 185, 255, 0.4)" borderColor="rgba(116, 185, 255, 0.4)" />
//                     )}
//                 </div>
//             </div>
//             <div className="row main-content">

//             </div>
//             <div className="row">
//                 <div className="col-10 col-m-10 p-2 mx-auto">
//                     <div className="loader-container">
//                     <Loader
//                         dataAreas={dataAreas}
//                         canvasSize={canvasSize}
//                         configuration={reservedKeywords}
//                         loadDataAreas={handleDataAreas}
//                         loadImageData={handleLoadImage}
//                         exportDataArea={exportDataAreas}
//                         fileName={fileName}
//                         setFileName={setFileName}
//                         loadedImages={loadedImages}
//                     />
//                     </div>
//                 </div>
//             </div>

//             <div className="row">
//                 <div className="col-10 col-m-10 p-2 mx-auto">
//                     <Displayer
//                         dataAreas={dataAreas}
//                         editedDataArea={editedDataArea}
//                         configuration={reservedKeywords}
//                         updateDataAreas={updateDataAreas}
//                         deleteDataAreaFromDataAreas={deleteDataAreaFromDataAreas}
//                         editDataArea={editDataArea}
//                     />
//                 </div>
//             </div>

//             <div className="row" id="jsonViewer">
//                 <div className="col-10 col-m-10 p-2 mx-auto">
//                     <Exporter 
//                         dataToExport={dataToExport} 
//                         fileName={fileName}
//                     />
//                 </div>
//             </div>
//         </div>
//     );
// };

// export default MainPage;
import { useState, useEffect } from "react";
import { ImageData } from "../../interfaces/imageData";
import { DataArea } from "../../interfaces/dataArea";
import { Labelme } from "../../interfaces/labelme";
import { Size } from "../../interfaces/size";

import Loader from "../../labelmeComponents/Loader/loader";
import Displayer from "../../labelmeComponents/Displayer/displayer";
import Canvas from "../../labelmeComponents/Canvas/canvas";
import Box from "../../labelmeComponents/Box/box";
import Exporter from "../../labelmeComponents/Exporter/exporter";
import { ReservedKeyword } from "../../interfaces/reservedKeyword";
import { overrideDefaultConfigWithReservedKeywordConfig } from "../../utils/draw";
import CollapseBox from "../../labelmeComponents/CollapseBox/collapseBox";
import { getLabelmeImages } from '../../utils/api';
import DropdownFranchise from "../../components/dropdowns/DropdownFranchise";
import DropdownLocationSingle from "../../components/dropdowns/DropdownLocationSingle";
import { useData } from "../../hooks/useData";
import '../../css/labelme.css';

const MainPage = () => {
    const reservedKeywords: ReservedKeyword = {
        "blackout": {
            polygonColor: "rgba(0,0,0,1)",
            description: "Reserved keyword that blacks out the polygon. Color cannot be modified"
        },
        "ginger": {
            polygonColor: "rgba(235, 47, 6, 1.0)",
            description: "Reserved keyword that sets the color to an intense tomato red. Color cannot be modified"
        }
    };

    const {
        selectedFranchise,
        selectedLocation
    } = useData();

    const [canvasSize, setCanvasSize] = useState<Size<number> | undefined>(undefined);
    const [imageData, setImageData] = useState<ImageData | undefined>(undefined);
    const [dataAreas, setDataAreas] = useState<Array<DataArea> | undefined>([]);
    const [editedDataArea, setEditedDataArea] = useState<DataArea | undefined>(undefined);
    const [dataToExport, setDataToExport] = useState<Labelme | undefined>(undefined);
    const [mode, setMode] = useState<string | undefined>(undefined);
    const [fileName, setFileName] = useState<string>("enter");
    const [loadedImages, setLoadedImages] = useState<Map<string, string>>(new Map());

    const fetchImages = async (franchiseId: number, locationId: number) => {
        const images = await getLabelmeImages(franchiseId, locationId);
        setLoadedImages(images);
    };

    useEffect(() => {
        if (selectedFranchise && selectedLocation){
            fetchImages(selectedFranchise['id'] as unknown as number, selectedLocation['id'] as unknown as number);
        }
    }, [selectedLocation]);

    const handleCanvasSize = (canvasSize: Size<number> | undefined) => {
        setCanvasSize(canvasSize);
    };

    const handleLoadImage = (imageData: ImageData | undefined) => {
        setImageData(imageData);
        if (imageData === undefined) {
            setDataAreas([]);
        }
    };

    const handleDataAreas = (dataAreas: Array<DataArea> | undefined) => {
        setDataAreas(dataAreas);
    };

    const handleMode = (mode: string) => {
        setMode(mode);
    };

    const updateDataAreas = (dataArea: DataArea) => {
        if (dataAreas !== undefined && dataAreas.length > 0) {
            dataAreas.forEach((dataArea) => overrideDefaultConfigWithReservedKeywordConfig(dataArea, reservedKeywords));
            const idxOfTargetDataArea = dataAreas.findIndex(x => x.id === dataArea.id);
            if (idxOfTargetDataArea !== -1) {
                dataAreas[idxOfTargetDataArea] = dataArea;
                setMode("Waiting")
                setEditedDataArea(undefined);
                setDataAreas([...dataAreas]);
            }
        }
    };

    const editDataArea = (dataArea: DataArea) => {
        setMode(`Editing Data Area with label: ${dataArea.label}`);
        setEditedDataArea(dataArea);
    };

    const deleteDataAreaFromDataAreas = (dataArea: DataArea) => {
        if (dataAreas !== undefined && dataAreas.length > 0) {
            setDataAreas([...dataAreas.filter((x) => x.id !== dataArea.id)]);
        }
    };

    const exportDataAreas = (labelme: Labelme) => {
        setDataToExport(labelme);
    };

    return (
        <div className="main-container">
            <div className="main-content">
                <div className="row">
                    <div className="col-10 col-m-10 p-2 mx-auto">
                        <DropdownFranchise />
                        <DropdownLocationSingle />
                    </div>
                </div>

                <div className="row">
                    <div className="col-10 col-m-10 p-2 mx-auto">
                        <CollapseBox
                            text="How to label?"
                            backgroundColor="#636e72"
                            borderColor="#2d3436"
                        >
                            <br />
                            <p className="color-white font-small font-lighter">select a franchise and location from the dropdown up top (if you dont see any, refresh or contact your admin to be given locations)</p>
                            <br />
                            <p className="color-white font-small font-lighter">images should show up one on top of the other. inspect these images, and find the one you want to draw on.</p>
                            <br />
                            <p className="color-white font-small font-lighter">depending on which image you choose, choose the corresponding fileName from "enter: orderboard, exit: pickup window, pull: the final or only pull forward, pull1: the first pull forward if there are two, lobby_areas: lobby area, mobile: mobile orderingg spots</p>
                            <br />
                            <p className="color-white font-small font-lighter">WHICHEVER FILENAME YOU PRESS LAST WILL BE THE LOCATION IT IS SENT TO... if you pressed the wrong fileName, just delete your labels and press the correct one, but if you export and send the wrong data to aws, that wouldnt be great.</p>
                            <br />
                            <p className="color-white font-small font-lighter">depending on which fileName is chosen, as you draw, a different set of labels will be drawn. pull, pull1, and mobile will increment from either pull or mobile 1-n. enter will do orderboard1, preorderboard1, then 2, etc...</p>
                            <br />
                            <p className="color-white font-small font-lighter">you can toggle between "set blackout" and "set label" to either draw the corresponding labels or draw blackout regions</p>
                            <br />
                            <p className="color-white font-small font-lighter">note that when fileName is changed, "set blackout" will be changed to "set label"</p>
                            <br />
                            <br />
                            <p className="color-white font-small font-lighter">If you are in draw mode, right click to erase points</p>
                            <br />
                            <p className="color-white font-small font-lighter">To close a shape, you must finish clicking the starting point. It will highlight in yellow when the mouse is over</p>
                            <br />
                            <p className="color-white font-small font-lighter">To edit the shape, once you click edit in the labels, you can go to the canvas and edit the points</p>
                            <br />
                            <br />
                            <p className="color-white font-small font-lighter">once you are done drawing the labels and editing them, you can delete anything you didnt want (such as preorderboard areas in enter)</p>
                            <br />
                            <p className="color-white font-small font-lighter">press export. this will show you the json (the data of the file that you have created)</p>
                            <br />
                            <p className="color-white font-small font-lighter">if you are happy with what you have produced and are confident that the fileName is correct, press "send to aws"</p>
                            <br />
                            <p className="color-white font-small font-lighter">you are now free to press on the next picture you would like to work on.</p>
                        </CollapseBox>
                    </div>
                </div>

                <div className="row">
                    <div className="col-10 col-m-10 p-2 mx-auto">
                        <p className="color-white font-small">Operation: <b><i>{mode ?? "Waiting"}</i></b></p>
                    </div>
                </div>

                <div className="row">
                    <div className="col-10 col-m-10 p-2 mx-auto">
                        {imageData !== undefined ? (
                            <Canvas
                                imageData={imageData}
                                dataAreas={dataAreas ?? []}
                                editedDataArea={editedDataArea}
                                sendDataArea={handleDataAreas}
                                handleCanvasSize={handleCanvasSize}
                                handleMode={handleMode}
                                fileName={fileName}
                            />
                        ) : (
                            <Box text="Waiting for image" backgroundColor="rgba(116, 185, 255, 0.4)" borderColor="rgba(116, 185, 255, 0.4)" />
                        )}
                    </div>
                </div>

                <div className="row">
                    <div className="col-10 col-m-10 p-2 mx-auto">
                        <Displayer
                            dataAreas={dataAreas}
                            editedDataArea={editedDataArea}
                            configuration={reservedKeywords}
                            updateDataAreas={updateDataAreas}
                            deleteDataAreaFromDataAreas={deleteDataAreaFromDataAreas}
                            editDataArea={editDataArea}
                        />
                    </div>
                </div>

                <div className="row" id="jsonViewer">
                    <div className="col-10 col-m-10 p-2 mx-auto">
                        <Exporter 
                            dataToExport={dataToExport} 
                            fileName={fileName}
                        />
                    </div>
                </div>
            </div>

            <div className="loader-container">
                <Loader
                    dataAreas={dataAreas}
                    canvasSize={canvasSize}
                    configuration={reservedKeywords}
                    loadDataAreas={handleDataAreas}
                    loadImageData={handleLoadImage}
                    exportDataArea={exportDataAreas}
                    fileName={fileName}
                    setFileName={setFileName}
                    loadedImages={loadedImages}
                    location={selectedLocation && selectedLocation.id ? selectedLocation.id : null}
                />
            </div>
        </div>
    );
};

export default MainPage;
