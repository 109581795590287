/**
 * Presentational button component for refreshing data.
 */

export default function DateChangeButton({
  handleDateChange,
  dataCy = 'date-change-button',
  text = 'Last X Days',
  isSelected = false, // Prop to control the selected state
}: {
  handleDateChange: () => void;
  dataCy?: string;
  text?: string;
  isSelected?: boolean;
}): JSX.Element {
  return (
    <button
      style={{ maxHeight: '2.5em' }}
      onClick={handleDateChange}
      data-cy={dataCy}
      className={`button border border-blue-400 
        rounded py-1 px-2 text-xs
        ${isSelected ? 'bg-blue-600 text-white' : 'bg-transparent text-blue-400'} 
        ${isSelected ? 'hover:bg-blue-700' : 'hover:bg-blue-400 hover:text-white'} 
        active:bg-blue-600 active:text-white`} // Ensure button gets darker on hover and when active
    >
      {text}
    </button>
  );
}
