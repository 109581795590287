/**
 * Presentational component for changing the display name.
 */
import { useState } from 'react';
import SectionTitle from './SectionTitle';
import ModalBasic from '@/components/modals/ModalBasic';
import { useToast } from '@/hooks/useToast';
import { useLoad } from '@/hooks/useLoad';

export default function ChangeDisplayNameSection({
  displayName,
  locationId,
  franchiseId,
  handleInputChange,
  handleLocationDelete
}: {
  displayName: string;
  locationId: string;
  franchiseId: string;
  handleInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleLocationDelete: (locationId: string, franchiseId: string) => Promise<void>;
}): JSX.Element {
  const [isOpen, setIsOpen] = useState(false);
  const { setIsLoading } = useLoad();
  const { setToast } = useToast();

  return (
    <section>
      <SectionTitle title="Change Display Name" />
      <div className="flex items-center justify-between">
        <input
          type="text"
          value={displayName}
          className="form-input"
          onChange={handleInputChange}
          data-cy="display-name-input"
        />
        <div className="flex flex-col px-4 py-2">
          <button
            className="btn bg-rose-500 hover:bg-rose-600 text-white"
            onClick={() => { setIsOpen(true); }}
            data-cy="delete-day-part-button"
          >
            Delete location
          </button>
        </div>
      </div>
      <div hidden={true}>
        <ModalBasic
          title="Delete Location"
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          dataCy="modal-delete-user"
        >
          <div className="flex flex-col gap-4 min-w-96 p-4">
            <h3 className='text-lg font-bold'>
              Are you sure you want to delete {displayName}?
            </h3>
            <h3>
              All details of this location will be permanently removed, and all users currently having access to it will lose that access. This action cannot be undone.
            </h3>
            <div className="flex flex-rowƒsp justify-end">
              <div className='pr-1'>
                <button
                  className="btn bg-rose-500 hover:bg-rose-600 text-white"
                  onClick={() => {
                    setIsOpen(false);
                    setIsLoading(true);
                    handleLocationDelete(locationId, franchiseId)
                      .then(() => {
                        setToast({
                          show: true,
                          message: 'Location deleted succesfully',
                          type: 'success',
                        });
                      })
                      .catch((error) => {
                        console.error('Error deleting location', error);
                        setToast({
                          show: true,
                          message: 'Error deleting location',
                          type: 'error',
                        });
                      })
                      .finally(() => {
                        setIsLoading(false);
                      });
                  }}
                  data-cy="save-user-button"
                >
                  Yes
                </button>
              </div>
              <div className='pl-1'>
                <button
                  className="btn border-slate-200 dark:border-slate-700 hover:border-slate-300 dark:hover:border-slate-600 shadow-sm text-indigo-500"
                  onClick={() => { setIsOpen(false); }}
                  data-cy="delete-user-button"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </ModalBasic>
      </div>
    </section>
  );
}
