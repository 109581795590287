import { GroupResponse } from '@/types/GroupResponse';
import React from 'react';

export default function GroupModal({
    groupOptions,
    selectedGroup, 
    setSelectedGroup, 
}: {
    groupOptions: GroupResponse[];
    selectedGroup: GroupResponse | null;
    setSelectedGroup: React.Dispatch<React.SetStateAction<GroupResponse | null>>;
}): JSX.Element {
  
  const handleSelection = (group: GroupResponse) => {
    group.groupId === selectedGroup?.groupId ? setSelectedGroup(null) : setSelectedGroup(group);
  };

  return (
    <div className="flex flex-col space-y-3 my-2" style={{ maxHeight: '300px', overflowY: 'scroll' }}>
      {groupOptions.map((group, i) => (
        <div 
          key={i}
          className={`px-6 cursor-pointer ${group === selectedGroup ? 'bg-blue-400 text-white' : 'bg-transparent'}`}
          onClick={() => handleSelection(group)}
        >
          {group.displayName ? group.displayName : group.groupId}
        </div>
      ))}
    </div>
  );
}
