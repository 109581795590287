'use client';

import { useState, useEffect } from 'react';
import { useData } from '@/hooks/useData';
import { type DayPartData } from '@/types/DayPartData';
import {
  Bar,
  BarChart,
  CartesianGrid,
  type TooltipProps,
  XAxis,
  YAxis,
} from 'recharts';
import { formatSeconds } from '@/utils/time';
import { type LocationData } from '@/types/TrendLineData';

import { Card, CardContent } from '@/components/ui/card';
import {
  type ChartConfig,
  ChartContainer,
  ChartTooltip,
} from '@/components/ui/chart';
import { type Franchise } from '@/types/Franchise';
import CardHeaderGrid from '@/components/ui/CardHeaderGrid';

// Data type interfaces
interface DayPartChartData {
  locationData: LocationData[];
  barData: BarData[];
}

interface BarData {
  dayPart: string;
  [id: string]: number | string; // locationId-time for service time at daypart
}

// Data type converters
function orderDayPartNames(
  data: DayPartChartData,
  selectedFranchise: Franchise | null
): DayPartChartData {
  let orderMap: Record<string, number>;
  // TODO: use franchise configuration to make this dynamically
  if (selectedFranchise?.id === '8') {
    // Hard code for miguel's
    orderMap = {
      Breakfast: 1,
      Lunch: 2,
      Afternoon: 3,
      Evening: 4,
    };
  } else if (selectedFranchise?.id === '29') {
    // Hard code for culver's
    orderMap = {
      Breakfast: 1,
      Lunch: 2,
      Snack: 3,
      Dinner: 4,
      Evening: 5,
    };
  } else {
    orderMap = {
      Breakfast: 1,
      Lunch: 2,
      Snack: 3,
      Dinner: 4,
      Evening: 5,
    };
  }

  data.barData.sort((a, b) => {
    return (
      (orderMap[a.dayPart] ?? Infinity) - (orderMap[b.dayPart] ?? Infinity)
    );
  });
  return data;
}

const chartConfig = {
  dayPart: {
    label: 'Day Part',
  },
  time: {
    label: 'Service Time',
  },
} satisfies ChartConfig;

export function DayPartsChart({
  dayPartsData,
  colorMap,
}: {
  dayPartsData: DayPartData[] | null;
  colorMap: Record<string, string>;
}): JSX.Element {
  const [chartData, setChartData] = useState<DayPartChartData | null>(null);
  const [dayPartNames, setDayPartNames] = useState<string[]>();
  const [flatData, setFlatData] = useState<any[]>();
  const [expand, setExpand] = useState<boolean>(false);
  const { selectedFranchise } = useData();

  const createFlatBarData = (
    locationData: LocationData[] | undefined,
    names: string[] | undefined
  ): any[] => {
    const flatData: any[] = [];
    // TODO: make FlatData a type
    locationData?.forEach((loc) => {
      names?.forEach((rName) => {
        flatData.push({
          dataKey: `${rName}`,
          key: `${loc.locationName}`,
          name: `${loc.locationName}`,
          fill: loc.color,
        });
      });
    });
    return flatData;
  };

  // Convert DayPartData[] to DayPartChartData[]
  function convertToDayPartChartData(
    rawData: DayPartData[] | null
  ): DayPartChartData {
    const newData = {
      locationData: [],
      barData: [],
    } as DayPartChartData;
    const dateMap = new Map();

    if (rawData) {
      // Add location data
      newData.locationData = rawData.map((data) => ({
        locationName: data.locationName,
        locationId: data.locationId,
        color: colorMap[data.locationId],
      }));

      // Add bar data for this location
      rawData.forEach((loc) => {
        loc.dayParts.forEach((dp) => {
          const serviceTimeKey = `${loc.locationName}`;

          if (!dateMap.has(dp.name)) {
            dateMap.set(dp.name, {
              [serviceTimeKey]: dp.averageServiceTime,
            });
          } else {
            const existingData = dateMap.get(dp.name);
            existingData[serviceTimeKey] = dp.averageServiceTime;
          }
        });
      });
    }

    newData.barData = Array.from(dateMap.entries()).map(([dayPart, data]) => ({
      dayPart,
      ...data,
    }));

    return orderDayPartNames(newData, selectedFranchise);
  }

  const CustomTooltip: React.FC<TooltipProps<any, any>> = ({
    active,
    payload,
  }) => {
    if (active && payload?.length) {
      const sortedPayload = [...payload].sort(
        (b, a) => (b.payload[a.dataKey] ?? 0) - (a.payload[b.dataKey] ?? 0)
      );
      return (
        <div
          style={{
            backgroundColor: '#fff',
            border: '1px solid #ccc',
            padding: '5px',
            borderRadius: '4px',
            fontSize: 'small',
          }}
        >
          {sortedPayload.map((pl) => (
            <div
              key={pl.dataKey}
              style={{ display: 'flex', alignItems: 'center' }}
            >
              <div
                style={{
                  width: '1em',
                  height: '1em',
                  backgroundColor: pl.color,
                  marginRight: '0.5em',
                }}
              />
              <p>{`${pl.name} ${pl.payload.dayPart} - Time: ${formatSeconds(
                pl.payload[pl?.dataKey || 0]
              )}`}</p>
            </div>
          ))}
        </div>
      );
    }

    return null;
  };

  useEffect(() => {
    // Create chart data
    const newChartData = convertToDayPartChartData(dayPartsData);
    // Set chart data
    setChartData(newChartData);
    // Set day part names
    const names = newChartData?.barData.map((bd) => bd.dayPart);
    setDayPartNames(names);
    // Set flat version of bar data
    setFlatData(createFlatBarData(newChartData?.locationData, names));
  }, [dayPartsData]);

  if (!chartData || !dayPartNames) {
    return <div>{null}</div>;
  }
  return (
    <div className={`${expand ? 'w-100' : 'w-50'} transition-all duration-300 ease-in-out`}>
      <Card>
        <CardHeaderGrid
            title={'Day Parts'}
            expand={expand}
            setExpand={setExpand}
          ></CardHeaderGrid>
        <CardContent>
          <ChartContainer config={chartConfig}>
            <BarChart accessibilityLayer data={chartData.barData}>
              <CartesianGrid vertical={false} />
              <XAxis
                dataKey="dayPart"
                tickLine={false}
                tickMargin={10}
                axisLine={false}
              />
              <YAxis
                label={{
                  value: 'Time Spent',
                  angle: -90,
                  position: 'insideLeft',
                }}
                tickFormatter={(value) => formatSeconds(value)}
              />
              <ChartTooltip cursor={false} content={<CustomTooltip />} />
              {[
                ...new Map(flatData?.map((item) => [item.key, item])).values(),
              ].map((data) => (
                <Bar
                  dataKey={data.key}
                  key={data.dayPart}
                  fill={data.fill}
                  name={data.name}
                  radius={4}
                />
              ))}
            </BarChart>
          </ChartContainer>
        </CardContent>
      </Card>
    </div>
  );
}
