'use client';

import { useState, useEffect } from 'react';
import {
  CartesianGrid,
  Line,
  LineChart,
  type TooltipProps,
  XAxis,
  YAxis,
} from 'recharts';
import { type TrendLineData } from '@/types/TrendLineData';
import { formatSeconds } from '@/utils/time';

import { Card, CardContent } from '@/components/ui/card';
import {
  type ChartConfig,
  ChartContainer,
  ChartTooltip,
} from '@/components/ui/chart';
import { type Average } from '@/types/Average';
import CardHeaderGrid from '@/components/ui/CardHeaderGrid';

const chartConfig = {
  volume: {
    label: 'Volume',
  },
  serviceTime: {
    label: 'Service Time',
  },
  date: {
    label: 'Date',
  },
} satisfies ChartConfig;

export function TrendLineChart({
  averageData,
  chartType,
  colorMap,
}: {
  averageData: Average[] | null;
  chartType: string;
  colorMap: Record<string, string>;
}): JSX.Element {
  const [trendData, setTrendData] = useState<TrendLineData | null>(null);
  const [metric, setMetric] = useState<string>('');
  const [expand, setExpand] = useState<boolean>(false);

  useEffect(() => {
    const newChartData = convertToTrendLineData(averageData);

    setTrendData(newChartData);
    setMetric(chartType);
  }, [averageData]);

  // Convert Average[] to TrendLineData[]
  function convertToTrendLineData(rawData: Average[] | null): TrendLineData {
    const newData = {
      locationData: [] as any[],
      lineData: [] as any[],
    };
    const dateMap = new Map();

    if (rawData) {
      // Add location data
      newData.locationData = rawData.map((data) => ({
        locationName: data.locationName,
        locationId: data.locationId,
        color: colorMap[data.locationId],
      }));

      rawData.forEach((loc) => {
        const volumeKey = `${loc.locationId}-volume`;
        const serviceTimeKey = `${loc.locationId}-serviceTime`;

        loc.trendData.forEach((td) => {
          if (!dateMap.has(td.date)) {
            dateMap.set(td.date, {
              [volumeKey]: td.averageVolume,
              [serviceTimeKey]: td.averageServiceTime,
            });
          } else {
            const existingData = dateMap.get(td.date);
            existingData[volumeKey] = td.averageVolume;
            existingData[serviceTimeKey] = td.averageServiceTime;
          }
        });
      });
    }

    newData.lineData = Array.from(dateMap.entries()).map(([date, data]) => ({
      date,
      ...data,
    }));

    return newData;
  }

  const CustomTooltip: React.FC<TooltipProps<any, any>> = ({
    active,
    payload,
  }) => {
    if (active && payload?.length) {
      const sortedPayload = [...payload].sort(
        (b, a) => (b.payload[a.dataKey] ?? 0) - (a.payload[b.dataKey] ?? 0)
      );
      return (
        <div
          style={{
            backgroundColor: '#fff',
            border: '1px solid #ccc',
            padding: '5px',
            borderRadius: '4px',
            fontSize: 'small',
          }}
        >
          {sortedPayload.map((pl) => (
            <div
              key={pl.dataKey}
              style={{ display: 'flex', alignItems: 'center' }}
            >
              <div
                style={{
                  width: '1em',
                  height: '1em',
                  backgroundColor: pl.color,
                  marginRight: '0.5em',
                }}
              />
              <p>
                {`${pl.name}`}
                {metric === 'volume'
                  ? (<span>{` - Vol: ${pl.payload[pl?.dataKey || 0]}`}</span>)
                  : (<span>{` - Time: ${formatSeconds(pl.payload[pl?.dataKey || 0])}`}</span>)
                  }
              </p>
            </div>
          ))}
        </div>
      );
    }

    return null;
  };

  return (
    <div className={`${expand ? 'w-100' : 'w-50'} transition-all duration-300 ease-in-out`}>
      <Card>
        <CardHeaderGrid
            title={metric === 'volume' ? 'Volume' : 'Service Time'}
            expand={expand}
            setExpand={setExpand}
          ></CardHeaderGrid>
        <CardContent>
          <ChartContainer config={chartConfig}>
            <LineChart
              accessibilityLayer
              data={trendData?.lineData}
              margin={{
                top: 20,
                right: 20,
                bottom: 20,
                left: 20,
              }}
            >
              <CartesianGrid vertical={false} />
              <XAxis
                angle={-25}
                dataKey="date"
                axisLine={false}
                textAnchor={'end'}
                tickMargin={4}
              />
              {metric === 'volume'
                ? (
                <YAxis
                  axisLine={false}
                  tickMargin={4}
                  label={{
                    value: 'Volume',
                    angle: -90,
                    position: 'insideLeft',
                  }}
                />
              )
              : (
                <YAxis
                  axisLine={false}
                  tickMargin={4}
                  label={{
                    value: 'Service Time',
                    angle: -90,
                    position: 'left',
                  }}
                  tickFormatter={(value) => formatSeconds(value)}
                />
              )}
              <ChartTooltip cursor={false} content={<CustomTooltip />} />
              {trendData?.locationData.map((loc) => (
                <Line
                  dataKey={`${loc.locationId}-${metric}`}
                  key={`${loc.locationId}-${metric}`}
                  name={loc.locationName}
                  type="natural"
                  stroke={loc.color}
                  strokeWidth={2}
                  dot={false}
                  activeDot={{
                    r: 6,
                  }}
                />
              ))}
            </LineChart>
          </ChartContainer>
        </CardContent>
      </Card>
    </div>
  );
}
