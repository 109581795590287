import { useState, useEffect } from "react";
import { callMothershipDotenv, getMothershipMetrics } from "../../utils/api.ts";
import ModalBasic from "./ModalBasic";
import { Toaster } from "../ui/sonner";

export default function EnvConfigModalContainer({
  isOpen,
  setIsOpen,
  locationId,
  franchiseId,
}: {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  locationId: string;
  franchiseId: string;
}) {
  const [envVariables, setEnvVariables] = useState<any>({});
  const [editingVariable, setEditingVariable] = useState<string | null>(null);
  const [newValue, setNewValue] = useState<string>("");
  const [action, setAction] = useState<"ADD" | "REMOVE" | "EDIT">("ADD");
  const [selectedAvoidKeywords, setSelectedAvoidKeywords] = useState<string[]>([]);
  const [batchChanges, setBatchChanges] = useState<any[]>([]); // To store multiple changes

  const cameraVariables = [
    "ENTRY_CAMERA", "ENTRY_CAMERA_2", "EXIT_CAMERA", "PULL_FORWARD_CAMERA_1",
    "PULL_FORWARD_CAMERA_2", "CAMERA", "MOBILE_CAMERA", "PRE_CAMERA"
  ];

  const avoidKeywords = ['backend', 'frontend', 'streams', 'lobby', 'dt', 'kinesis'];

  const idVariables = ["LOCATION_ID", "DEVICE_ID", "FRANCHISE_ID"];

  // Fetch .env variables from the backend when the modal opens or locationId/franchiseId changes
  useEffect(() => {
    async function fetchEnvVariables() {
      try {
        // Fetch the metrics data based on franchiseId and locationId
        const envData = await getMothershipMetrics(franchiseId, [locationId]);
  
        // Check if envData contains locationMetrics
        console.log("Fetched Environment Variables:", envData);
  
        if (envData[locationId]) {
          // Successfully found the locationId in locationMetrics, now extract env_contents
          const envContents = envData[locationId].env_contents;
  
          // Parse and set environment variables
          const parsedEnv = parseEnvContents(envContents);
          setEnvVariables(parsedEnv);
  
          // Set selectedAvoidKeywords from AVOID or default to an empty array if not present
          const avoidKeywordsFromEnv = parsedEnv['AVOID'] || [];
          setSelectedAvoidKeywords(avoidKeywordsFromEnv); // This will trigger a re-render with the updated avoid keywords
  
          // Log for debugging
          console.log('Selected Avoid Keywords:', selectedAvoidKeywords);
        } else {
          console.error(`No data found for locationId: ${locationId}`);
        }
      } catch (error) {
        console.error("Error fetching environment variables:", error);
      }
    }
  
    if (isOpen) {
      fetchEnvVariables();
    }
  }, [isOpen, locationId, franchiseId]); // Add locationId and franchiseId as dependencies
  

  // Helper function to parse the env_contents string into an object
  const parseEnvContents = (envContents: string): { [key: string]: string } => {
    const envObject: { [key: string]: string } = {};
    const lines = envContents.split("\n");

    lines.forEach((line) => {
      const [key, value] = line.split("=");
      if (key && value) {
        if (cameraVariables.includes(key)) {
          envObject[key.trim()] = extractCameraIp(value.trim());
        } else if (key === "CAMERAS_TO_AVOID") {
          envObject[key.trim()] = extractCamerasToAvoid(value.trim());
        } else if (key === "SECOND_SCREEN_CAMERA") {
          envObject[key.trim()] = value.trim() === "true" ? "true" : value.trim() === "false" ? "false" : null;
        } else if (key === "AVOID") {
          envObject[key.trim()] = value.split(",").map(v => v.trim());
        } else if (idVariables.includes(key)) {
          envObject[key.trim()] = value.trim();  // Add ID variables
        } else {
          envObject[key.trim()] = value.trim();
        }
      }
    });

    return envObject;
  };

  // Extract last octet from the IP address for camera-related variables
  const extractCameraIp = (value: string): string => {
    const ipMatch = value.match(/@([0-9]+\.[0-9]+\.[0-9]+\.[0-9]+):554/);
    if (ipMatch && ipMatch[1]) {
      const ipParts = ipMatch[1].split('.');
      return ipParts[ipParts.length - 1]; // Return the last octet of the IP
    }
    return value;
  };

  // Extract final octet for CAMERAS_TO_AVOID
  const extractCamerasToAvoid = (value: string): string => {
    const ips = value.split(',');
    const lastOctets = ips.map((ip) => {
      const ipParts = ip.trim().split('.');
      return ipParts[ipParts.length - 1]; // Get the last octet of each IP
    });
    return lastOctets.join(',');
  };

  // Handle the action to modify an environment variable
  const handleModifyEnvVariable = async () => {
    if (batchChanges.length === 0) {
      console.error("No changes to apply.");
      return;
    }
  
    // Apply all changes in batch
    for (const change of batchChanges) {
      const values = change.value.split(',');
  
      if (change.variable === "AVOID") {
        for (const value of values) {
          const success = await callMothershipDotenv(locationId, change.variable, change.action, value.trim());
          if (!success) {
            console.error(`Failed to modify ${change.variable} with value ${value}.`);
          }
        }
      } else {
        const success = await callMothershipDotenv(locationId, change.variable, change.action, change.value);
        if (!success) {
          console.error(`Failed to modify ${change.variable}.`);
        }
      }
    }
  
    // Clear batch changes after applying them
    setBatchChanges([]);  // Make sure batch is reset after processing
  };
  
  

  // Handle the addition or removal of IPs from CAMERAS_TO_AVOID
  const handleCamerasToAvoidChange = (action: "ADD" | "REMOVE", value: string) => {
    const newCameras = [...(envVariables["CAMERAS_TO_AVOID"]?.split(',') || [])];
    if (action === "ADD") {
      newCameras.push(value);
    } else {
      const index = newCameras.indexOf(value);
      if (index > -1) newCameras.splice(index, 1);
    }
    const updatedValue = newCameras.join(",");

    // Add the change to the batch
    setBatchChanges((prev) => [
      ...prev,
      { variable: "CAMERAS_TO_AVOID", action: action, value: updatedValue },
    ]);
  };

// Handle keyword list changes (AVOID)
const handleKeywordChange = (keyword: string) => {
  setSelectedAvoidKeywords((prevKeywords) => {
    // Add or remove keyword
    const newKeywords = prevKeywords.includes(keyword)
      ? prevKeywords.filter(k => k !== keyword) // Remove the keyword
      : [...prevKeywords, keyword]; // Add the keyword

    // Only add changes to the batch if they are different
    if (newKeywords !== prevKeywords) {
      setBatchChanges((prev) => [
        ...prev,
        { variable: "AVOID", action: newKeywords.includes(keyword) ? "ADD" : "REMOVE", value: newKeywords.join(",") },
      ]);
    }

    return newKeywords;
  });
};

  // Handle second screen camera change (radio buttons)
  const handleSecondScreenCameraChange = (value: "true" | "false" | null) => {
    // Update the state of SECOND_SCREEN_CAMERA based on the user's choice
    setEnvVariables((prevState: any) => ({
      ...prevState,
      "SECOND_SCREEN_CAMERA": value,
    }));

    // Add the change to the batch
    setBatchChanges((prev) => [
      ...prev,
      { variable: "SECOND_SCREEN_CAMERA", action: value ? "ADD" : "REMOVE", value: value || "" },
    ]);
  };

  return (
    <ModalBasic
      title="Configure Environment Variables"
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      className="w-[600px]"
    >
      <Toaster />
      <div className="flex flex-col gap-4 p-6">
        <h3 className="text-lg font-semibold">Environment Variables for Machine {locationId}</h3>

        {/* Modal Body with Scrollable Content */}
        <div className="overflow-y-auto max-h-[400px]">

          {/* Streaming Cameras */}
          <div>
            <h4 className="text-sm font-medium">Streaming Cameras</h4>
            {cameraVariables.map((key) => (
              <div key={key} className="flex justify-between items-center border-b pb-2">
                <span className="font-medium">{key}</span>
                <span>{envVariables[key]}</span>
                <button
                  className="text-blue-500 hover:text-blue-700"
                  onClick={() => {
                    setEditingVariable(key);
                    setNewValue(envVariables[key]);
                    setAction("EDIT");
                  }}
                >
                  Edit
                </button>
              </div>
            ))}
          </div>

          {/* Cameras to Avoid */}
          <div>
            <h4 className="text-sm font-medium">Cameras to Avoid</h4>
            {envVariables["CAMERAS_TO_AVOID"]?.split(',').map((ip: string, idx: number) => (
              <div key={idx} className="flex justify-between items-center border-b pb-2">
                <span>{ip}</span>
                <button
                  className="text-red-500 hover:text-red-700"
                  onClick={() => handleCamerasToAvoidChange("REMOVE", ip)}
                >
                  Remove
                </button>
              </div>
            ))}
            <div className="mt-2">
              <button
                className="bg-blue-500 text-white px-4 py-2 rounded"
                onClick={() => handleCamerasToAvoidChange("ADD", newValue)}
              >
                Add IP
              </button>
            </div>
          </div>

          {/* Boolean Variables */}
          <div>
            <h4 className="text-sm font-medium">Second Screen Camera</h4>
            <div className="flex gap-4">
              <div>
                <input
                  type="radio"
                  id="secondScreenTrue"
                  name="secondScreenCamera"
                  checked={envVariables["SECOND_SCREEN_CAMERA"] === "true"}
                  onChange={() => handleSecondScreenCameraChange("true")}
                />
                <label htmlFor="secondScreenTrue" className="ml-2">True</label>
              </div>
              <div>
                <input
                  type="radio"
                  id="secondScreenFalse"
                  name="secondScreenCamera"
                  checked={envVariables["SECOND_SCREEN_CAMERA"] === "false"}
                  onChange={() => handleSecondScreenCameraChange("false")}
                />
                <label htmlFor="secondScreenFalse" className="ml-2">False</label>
              </div>
              <div>
                <input
                  type="radio"
                  id="secondScreenNA"
                  name="secondScreenCamera"
                  checked={!envVariables["SECOND_SCREEN_CAMERA"]}
                  onChange={() => handleSecondScreenCameraChange(null)}
                />
                <label htmlFor="secondScreenNA" className="ml-2">N/A</label>
              </div>
            </div>
          </div>

          {/* AVOID Keyword List */}
          <div>
            <h4 className="text-sm font-medium">AVOID Keywords</h4>
            {avoidKeywords.map((keyword) => (
              <div key={keyword} className="flex items-center">
                <input
                  type="checkbox"
                  checked={selectedAvoidKeywords.includes(keyword)}
                  onChange={() => handleKeywordChange(keyword)}
                />
                <span className="ml-2">{keyword}</span>
              </div>
            ))}
          </div>

          {/* ID Variables (Non-editable) */}
          <div>
            <h4 className="text-sm font-medium">ID Variables</h4>
            {idVariables.map((key) => (
              <div key={key} className="flex justify-between items-center border-b pb-2">
                <span className="font-medium">{key}</span>
                <span>{envVariables[key]}</span>
              </div>
            ))}
          </div>

          {/* Other Non-Commented Variables */}
          <div>
            <h4 className="text-sm font-medium">Other Variables</h4>
            {Object.entries(envVariables).filter(([key]) => !cameraVariables.includes(key) && !idVariables.includes(key) && key !== "CAMERAS_TO_AVOID").map(([key, value]) => (
              <div key={key} className="flex justify-between items-center border-b pb-2">
                <span>{key}</span>
                <span>{value}</span>
              </div>
            ))}
          </div>
        </div>

        <div className="mt-4 flex justify-end">
          <button
            className="bg-green-500 text-white px-4 py-2 rounded-lg hover:bg-green-600"
            onClick={handleModifyEnvVariable}
          >
            {action === "ADD" ? "Add Variable" : action === "REMOVE" ? "Remove Variable" : "Edit Variable"}
          </button>
        </div>
      </div>
    </ModalBasic>
  );
}
