import type { ChangeEventHandler } from 'react';
import { Listbox, Transition } from '@headlessui/react';

export interface OptionType {
  id: string;
  value: string;
  videoAccess?: boolean; // <-- added optional attribute
}

interface DropdownFilterProps {
  title: string;
  options: OptionType[];
  selected: OptionType[];
  handleChange: (id: string) => void;
  /**
   * Called when toggling the video access checkbox for a given item.
   * If you omit this prop, the checkbox won't be rendered.
   */
  handleVideoAccessChange?: (id: string, checked: boolean) => void;
  filterFunction?: ChangeEventHandler<HTMLInputElement>;
  selectAllFunction?: () => void;
  deselectAllFunction?: () => void;
  dataCy?: string;
}

export default function DropdownFilter({
  title,
  options,
  selected,
  handleChange,
  handleVideoAccessChange,
  filterFunction,
  selectAllFunction,
  deselectAllFunction,
  dataCy,
}: DropdownFilterProps): JSX.Element {
  const converter = { 0: 'line', 1: 'counter' };
  return (
    <Listbox
      as="div"
      className="relative inline-flex"
      data-cy={dataCy}
      multiple
    >
      <>
        <Listbox.Button
          className="btn w-full justify-between min-w-[12rem] bg-white dark:bg-slate-800 border-slate-200 dark:border-slate-700 hover:border-slate-300 dark:hover:border-slate-600 text-slate-500 hover:text-slate-600 dark:text-slate-300 dark:hover:text-slate-200"
          aria-label="Select option"
          data-cy="dropdown-button"
        >
          <span className="flex items-center">
            <span>{title}</span>
          </span>
          <svg
            className="shrink-0 ml-1 fill-current text-slate-400"
            width="11"
            height="7"
            viewBox="0 0 11 7"
          >
            <path d="M5.4 6.8L0 1.4 1.4 0l4 4 4-4 1.4 1.4z" />
          </svg>
        </Listbox.Button>

        <Transition
          className="z-10 absolute top-full left-0 w-full bg-white dark:bg-slate-800 border border-slate-200 dark:border-slate-700 py-1.5 rounded shadow-lg overflow-hidden mt-1"
          enter="transition ease-out duration-100 transform"
          enterFrom="opacity-0 -translate-y-2"
          enterTo="opacity-100 translate-y-0"
          leave="transition ease-out duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Listbox.Options
            className="font-medium text-sm text-slate-600 dark:text-slate-300 divide-y divide-slate-200 dark:divide-slate-700 focus:outline-none"
            style={{ maxHeight: '250px', overflowY: 'auto' }}
          >
            {filterFunction !== undefined && (
              <div className="flex items-center justify-between px-2 py-1">
                <input
                  onChange={filterFunction}
                  onKeyDown={(e) => {
                    // Prevent ListBox from handling spacebar or navigation keys
                    e.stopPropagation();
                  }}
                  type="text"
                  placeholder="Filter..."
                  className="w-full border-b border-t-0 border-l-0 border-r-0 pb-0.5 border-gray-200 dark:bg-slate-800"
                />
                <div className="flex space-x-1 ml-2">
                  {selectAllFunction !== undefined && (
                    <button
                      className="btn text-green-500 hover:text-green-600 text-sm py-0 px-1"
                      onClick={selectAllFunction}
                    >
                      All
                    </button>
                  )}
                  {deselectAllFunction !== undefined && (
                    <button
                      className="btn text-red-500 hover:text-red-600 text-sm py-0 px-1"
                      onClick={deselectAllFunction}
                    >
                      None
                    </button>
                  )}
                </div>
              </div>
            )}

            {options.map((option, optionIndex) => {
              // Find out if this option is currently selected
              const isSelected = selected.some((x) => x.id === option.id);

              // If it's in `selected`, fetch that actual object so we get the current videoAccess
              const selectedOption = selected.find((x) => x.id === option.id);

              return (
                <Listbox.Option key={optionIndex} value={option.id}>
                  {({ active }) => (
                    <div
                      className={`
                        flex items-center justify-between w-full py-2 px-3 cursor-pointer
                        ${active ? 'bg-slate-50 dark:bg-slate-700/20' : ''}
                        ${isSelected ? 'text-indigo-500' : ''}
                      `}
                    >
                      {/* Clicking the label triggers handleChange */}
                      <button
                        type="button"
                        className="flex-1 text-left"
                        onClick={() => {
                          handleChange(option.id);
                        }}
                      >
                        {converter.hasOwnProperty(option.value) ? converter[option.value] : option.value}
                      </button>

                      {/*
                        Only show the checkbox if:
                          1) The parent provided `handleVideoAccessChange`
                          2) This option has (or is meant to have) a videoAccess property
                          3) The item is selected
                      */}
                      {handleVideoAccessChange &&
                        option.videoAccess !== undefined &&
                        isSelected && (
                          <label
                            className="ml-2 flex items-center gap-1"
                            title="Enable or disable video access"
                          >
                            {/* Optional label text */}
                            <span className="text-xs text-slate-500">
                              Video
                            </span>
                            <input
                              type="checkbox"
                              className="form-checkbox rounded-full cursor-pointer border-gray-300 text-indigo-600 focus:ring-0 focus:ring-offset-0"
                              checked={selectedOption?.videoAccess ?? false}
                              onChange={(e) => {
 handleVideoAccessChange(
                                  option.id,
                                  e.target.checked
                                );
}
                              }
                            />
                          </label>
                        )}
                    </div>
                  )}
                </Listbox.Option>
              );
            })}
          </Listbox.Options>
        </Transition>
      </>
    </Listbox>
  );
}
