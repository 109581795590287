/**
 * Constants used throughout the application.
 */

// List of locations that use the custom video pipeline.
export const CUSTOM_VIDEO_LOCATIONS = ['24', '28', '47', '72', '73', '74', '97', '98', '100', '102', '118', '123', '125', '128', '157', '173', '204', '205', '209', '218', '225', '228', '231', '233', '234', '235', '238', '239', '240', '241', '242', '243', '245', '246', '249', '250', '252', '257', '259', '262', '266', '283', '285', '368', '371', '372', '378', '379', '382', '388', '395', '404', '405', '406', '407', '408', '412', '413', '414', '415', '416', '417', '418', '420', '421', '422', '423', '424', '426', '427', '428', '429', '431', '432', '434', '435', '436', '437', '438', '439', '440', '441', '442', '443', '447', '448', '449', '450', '451', '453', '454', '455', '456', '457', '458', '459', '460', '461', '462', '465', '466', '467', '468', '469', '470', '472', '474', '475', '477', '482', '484', '485', '486', '487', '488', '490', '491', '492', '494', '495', '496', '508', '509', '517', '518', '524', '527', '529', '530', '532', '545', '550', '552', '567', '571', '572', '575', '582'];
export const AVAILABLE_FILTERS = ['24h', '1h', '30min', '15min', '5min'];
export const PANDAFRANCHISE = '59';
export const MONITORSTREAMSENABLED = ['6', '59'];
// TODO:102,422                                                '259',

export const FLAGS = [
    'HighCost',  // order total over $50
    'NoCost',  // order total less than or equal to $0
    'ZeroTender',  // no cash amount tendered 
    'HighChange',  // order change greater than or equal to $50
    'LowTax',  // tax less than 4% of total
    'OffHours',  // 12AM - 8AM
];
