import { clsx, type ClassValue } from "clsx"
import { twMerge } from "tailwind-merge"

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}

export const presentationFormat = (subString: string) => {
  let res = String(subString).charAt(0).toUpperCase() + String(subString).slice(1); // capitalize
  res = res.replace(/([A-Z])/g, ' $1').trim(); // spaces at new words (capitals)
  return res;
}
