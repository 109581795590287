/**
 * A link that displays the departure time of a detection.
 */
import dateFormat from 'dateformat';

import { utcStringToLocalDate } from '../../utils/time';
import { useData } from '@/hooks/useData';
import { Link } from 'react-router-dom';
import { time } from 'console';

export default function OpenOrCloseTime({
  utcTime,
  locationId,
  isOpenTime,
  setDate,
  handleClick,
  setIsLive,
}: {
  utcTime: string;
  locationId: string;
  isOpenTime: boolean;
  setDate?: (date: Date) => void;
  handleClick?: () => void;
  setIsLive?: (isLive: boolean) => void;
}): JSX.Element {
  const { selectedLocations } = useData();
  const location = selectedLocations.find((loc) => loc.id === locationId);
  let localDate = new Date();
  if (location !== undefined && utcTime !== undefined && utcTime !== null) {
    localDate = utcStringToLocalDate(utcTime, location.timezone);
  } else {
    return <>--</>;
  }

  const valueDate = new Date(localDate.valueOf());
  const locale = location.timezone.includes('America') ? 'en-US' : 'es-ES';
  const formattedDate = Intl.DateTimeFormat(locale, {
    dateStyle: 'short', // Can be 'short', 'medium', 'long', or 'full'
    timeStyle: 'medium', // Can be 'short', 'medium', 'long', or 'full'
  }).format(new Date(localDate));

  const openTime = '6:00';
  const closeTime = '24:00';
  function getDateWithTime(baseDate: Date, timeStr: string): Date {
    const [hours, minutes] = timeStr.split(':').map(Number);
    const date = new Date(baseDate); // Clone the base date
    date.setHours(hours % 24, minutes, 0, 0); // Adjust for 24-hour clock
    if (hours >= 24) {
      date.setDate(date.getDate() + 1); // Move to the next day if hours >= 24
    }
    return date;
  }

  // Today's date
  const today = new Date();
  today.setSeconds(0, 0); // Reset seconds and milliseconds for consistenc

  // Get the corresponding Date objects
  const openDate = getDateWithTime(localDate, openTime);
  const closeDate = getDateWithTime(localDate, closeTime);

  // Check if the time falls into the "bad" range
  let isBadTime = false;

  if (isOpenTime) {
    isBadTime = localDate >= openDate;
  } else {
    isBadTime = localDate <= closeDate;
  }

  // Create the query parameters for the link.
  const params = {
    dt:
      dateFormat(valueDate, 'yyyy-mm-dd') +
      'T' +
      dateFormat(valueDate, 'HH:MM'),
    lid: location.id.toString(),
  };

  const urlSearchParams = new URLSearchParams(params);
  const url = '/cameras?' + urlSearchParams.toString();
  return (
    <Link
      className={`${
        isBadTime
          ? 'underline text-red-600 hover:text-red-800 visited:text-red-900' // Class for after 6 AM
          : 'underline text-green-600 hover:text-green-800 visited:text-green-900'
      }`}
      to={url}
      onClick={() => {
        if (setIsLive !== undefined) setIsLive(false);
        if (setDate !== undefined) setDate(valueDate);
        if (handleClick !== undefined) handleClick();
      }}
    >
      {formattedDate}
    </Link>
  );
}
