/**
 * Orders Page
 * This page is intended for customers with a Point Of Sale (POS)
 * integration to view their orders.
 */
import { useState, useEffect } from 'react';
import { calculateHourlyTotal } from '../../utils/orders';
import { getLocationOrders, getOrderTotals } from '../../utils/api';
import { useData } from '../../hooks/useData';
import type { Order } from '../../types/Order';

import type { ChartData } from 'chart.js';
import { tailwindConfig } from '../../utils/Utils';

import DropdownFranchise from '../../components/dropdowns/DropdownFranchise';
import DropdownLocationSingle from '../../components/dropdowns/DropdownLocationSingle';
import Datepicker from '../../components/time/Datepicker';
import HourlyTotalsGraph from '../../components/graphs/orders/HourlyTotalsGraph';
import WeeklyTotalsGraph from '../../components/graphs/orders/WeeklyTotalsGraph';
import OrdersTable from '../../components/tables/OrdersTable';
import PageLayout from '../../components/ui/PageLayout';
import { FLAGS } from '@/constants';

export default function OrdersContainer(): JSX.Element {
  const [hourlyChartData, setHourlyChartData] = useState<ChartData | null>(
    null
  );
  const [weeklyChartData, setWeeklyChartData] = useState<ChartData | null>(
    null
  );
  const [dailyOrders, setDailyOrders] = useState<Order[]>([]);
  const [fullDailyOrders, setFullDailyOrders] = useState<Order[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [appliedFilters, setAppliedFilters] = useState<Record<string, string[]>>({});
  const [selectedFilters, setSelectedFilters] = useState<Record<string, string[]>>({});

  const flagOptions = FLAGS; // only string values as default
  const [selectedFlags, setSelectedFlags] = useState<string[]>(flagOptions);

  const {
    userLocations,
    selectedDates,
    selectedFranchise,
    setSelectedDates,
    selectedLocation,
    setSelectedLocation,
  } = useData();

  function clearStringFilters(): void {
    setAppliedFilters({});
    setSelectedFilters({});
  }

  function handleRefresh(): void {
    clearStringFilters();
    fetchDailyOrders().catch((err) => {
      console.log(err);
    });
  }

  function filterOrders(dailyOrders: Order[], filters: Record<string, string[]>): Order[] {
    if (Object.keys(filters).length === 0) {
      return dailyOrders; // No selected filters
    }
    return dailyOrders.filter(order => (
      Object.keys(filters).every(k => (
        filters[k].includes(order[k as keyof Order] as string)
      ))
    ));
  }

  function flagOrders(orders: Order[]): Order[] {
    const flagOrder = (order: Order, flag: string) => {
      if (!order.flags) {
        order.flags = []
      }
      order.flags = [...order.flags, flag]
    };

    // Logic for determining each flag lives here
    orders.forEach(order => {
      if (selectedFlags.includes('HighCost') && order.total >= 50) {
        flagOrder(order, 'HighCost');
      } 
      if (selectedFlags.includes('NoCost') && order.total <= 0) {
        flagOrder(order, 'NoCost');
      } 
      if (selectedFlags.includes('ZeroTender') && order.paymentType === 'Cash' && order.tender === 0) {
        flagOrder(order, 'ZeroTender');
      } 
      if (selectedFlags.includes('HighChange') && order.change >= 50) {
        flagOrder(order, 'HighChange');
      } 
      if (selectedFlags.includes('LowTax') && (order.tax / order.total) < 0.04) {
        flagOrder(order, 'LowTax');
      } 
      if (selectedFlags.includes('OffHours')) {
        const hour = new Date(order.checkoutTime).getHours();
        if (hour >= 0 && hour < 8) {
          flagOrder(order, 'OffHours');
        }
      }
    });

    return orders;
  }

  // Fetch the daily orders.
  async function fetchDailyOrders(): Promise<void> {
    if (selectedLocation === null || selectedFranchise === null) {
      return;
    }
    setIsLoading(true);
    getLocationOrders(
      selectedFranchise.id,
      selectedDates[0],
      [selectedLocation])
      .then((dailyData) => {
        const filteredData = filterOrders(dailyData, appliedFilters);
        setFullDailyOrders(dailyData);
        setDailyOrders(flagOrders(filteredData));
        setHourlyChartData(constructHourlyChartData(filteredData));
        setIsLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching daily orders:', error);
        setIsLoading(false);
      });
  }

  // Fetch the weekly orders.
  async function fetchWeeklyOrders(): Promise<void> {
    if (selectedLocation === null || selectedFranchise === null) {
      return;
    }

    const oneWeekAgo = new Date(selectedDates[0]);
    oneWeekAgo.setDate(oneWeekAgo.getDate() - 7);

    getOrderTotals(
      selectedFranchise.id,
      oneWeekAgo,
      selectedDates[0],
      [selectedLocation.id],
      selectedLocation.timezone
    )
      .then((weeklyData) => {
        setWeeklyChartData(constructWeeklyChartData(weeklyData));
      })
      .catch((error) => {
        console.error('Error fetching weekly orders:', error);
      });
  }

  // Set available locations on franchise change.
  useEffect(() => {
    setSelectedLocation(
      userLocations.filter(
        (location) => location.franchiseId === selectedFranchise?.id
      )[0]
    );
  }, [selectedFranchise]);

  useEffect(() => {
    fetchDailyOrders().catch((error) => {
      console.error('Error fetching daily orders:', error);
    });
    fetchWeeklyOrders().catch((error) => {
      console.error('Error fetching weekly orders:', error);
    });
  }, [selectedLocation, selectedDates, appliedFilters]);

  // Construct the hourly chart data.
  function constructHourlyChartData(orders: Order[]): ChartData {
    const hourlyTotals = calculateHourlyTotal(orders, selectedDates[0]);

    const chartData = {
      labels: [
        '12am',
        '1am',
        '2am',
        '3am',
        '4am',
        '5am',
        '6am',
        '7am',
        '8am',
        '9am',
        '10am',
        '11am',
        '12pm',
        '1pm',
        '2pm',
        '3pm',
        '4pm',
        '5pm',
        '6pm',
        '7pm',
        '8pm',
        '9pm',
        '10pm',
        '11pm',
      ],
      datasets: [
        {
          label: 'Orders',
          data: Object.values(hourlyTotals),
          backgroundColor: (tailwindConfig().theme?.colors?.indigo as any)[500],
          hoverBackgroundColor: (
            tailwindConfig().theme?.colors?.indigo as any
          )[600],
          categoryPercentage: 0.66,
        },
      ],
    };
    return chartData;
  }

  // Construct the weekly chart data.
  function constructWeeklyChartData(
    rawData: Array<{
      date: string;
      total: number;
    }>
  ): ChartData {
    const chartData = {
      labels: rawData.map((day) => {
        return day.date;
      }),
      datasets: [
        {
          label: 'Orders',
          data: rawData.map((day) => day.total),
          backgroundColor: (tailwindConfig().theme?.colors?.indigo as any)[500],
          hoverBackgroundColor: (
            tailwindConfig().theme?.colors?.indigo as any
          )[600],
          categoryPercentage: 0.66,
        },
      ],
    };
    return chartData;
  }

  let total = 0;

  for (const order of dailyOrders) {
    total += order.total;
  }

  return (
    <PageLayout>
      <div className="flex flex-col gap-4" data-cy="orders">
        <div className="flex flex-wrap justify-between items-center gap-4">
          <div className="flex flex-wrap gap-4">
            {/* Franchise Selector */}
            <DropdownFranchise />
            {/* Location Selector */}
            {selectedLocation !== null && <DropdownLocationSingle />}
          </div>
          <Datepicker
            initDates={[selectedDates[0]]}
            handleDateChange={(dates) => {
              setSelectedDates(dates);
            }}
            mode="single"
          />
        </div>
        {hourlyChartData !== null && (
          <HourlyTotalsGraph
            data={hourlyChartData}
            handleRefresh={handleRefresh}
            total={total}
          />
        )}
        {weeklyChartData !== null && (
          <WeeklyTotalsGraph
            data={weeklyChartData}
            handleRefresh={handleRefresh}
          />
        )}
        <OrdersTable
          orders={dailyOrders}
          fullOrders={fullDailyOrders}
          handleRefresh={handleRefresh}
          setAppliedFilters={setAppliedFilters}
          selectedFilters={selectedFilters}
          setSelectedFilters={setSelectedFilters}
          selectedFlags={selectedFlags}
          setSelectedFlags={setSelectedFlags}
          flagOptions={flagOptions}
          isLoading={isLoading}
        />
      </div>
    </PageLayout>
  );
}
