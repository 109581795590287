/**
 * Presentational component for configuring location notifications.
 */

import type { Location } from '../../../../types/Location';
import SectionTitle from './SectionTitle';
import DropdownSelectMultiple from '../../../dropdowns/DropdownSelectMultiple';
import StandardButton from '../../../buttons/StandardButton';
import { useState } from 'react';

export default function LocationNotificationsSection({
  locations,
  selectedLocations,
  handleSelect,
  handleSave,
}: {
  locations: Location[];
  selectedLocations: Location[];
  handleSelect: (id: string) => void;
  handleSave: () => void;
}): JSX.Element {
  const [isOpen, setIsOpen] = useState(false);

  const options = locations.map((location) => ({
    id: location.id,
    value: location.displayName,
  }));

  const selected = selectedLocations.map((location) => ({
    id: location.id,
    value: location.displayName,
  }));

  return (
    <section>
      <SectionTitle title="Set Location Notifications" />
      <p className="text-slate-600 dark:text-slate-400 mb-4">
        You will receive push notifications for every location on this list.
      </p>
      <div className="grid grid-cols-2 gap-4">
        <DropdownSelectMultiple
          options={options}
          selected={selected}
          handleChange={handleSelect}
          title={`${selectedLocations.length} Selected`}
          dataCy="location-notifications-dropdown"
          open={isOpen}
          setOpen={setIsOpen}
        />
        <StandardButton
          dataCy="save-location-notifications-button"
          onClick={handleSave}
        >
          Save
        </StandardButton>
      </div>
    </section>
  );
}
