import { useState, useEffect, Fragment } from 'react';
import { useData } from '../../hooks/useData';
import { type GroupResponse } from '@/types/GroupResponse';
import PageLayout from '../../components/ui/PageLayout';
import TableauEmbed from '../../components/tableau/TableauEmbed'; // Import the Tableau component

export default function TableauPage({
  version = null,
}: {
  version?: string | null;
}): JSX.Element {
  const today = new Date();
  const oneWeekAgo = new Date();
  oneWeekAgo.setDate(today.getDate() - 7);

  return (
    <PageLayout>
      <div data-cy="tableau-page">
        {/* Page header */}
        <div className="sm:flex sm:justify-between sm:items-center mb-8">
          <div className="mb-4 sm:mb-0">
            <h1 className="text-2xl md:text-3xl text-slate-800 dark:text-slate-100 font-bold">
              Advanced Analytics 📊
            </h1>
          </div>
        </div>

        <div className="flex flex-col gap-4">
          {/* Tableau Embed */}
          <TableauEmbed />

        </div>
      </div>
    </PageLayout>
  );
}
