import { useState, useEffect } from 'react';
import { createGroup, deleteGroup, getAllGroups, patchUserProfile } from '../../../utils/api';
import type { UserProfile } from '../../../types/UserProfile';
import { useData } from '../../../hooks/useData';
import { useToast } from '../../../hooks/useToast';
import { useAuth } from '../../../hooks/useAuth';
import DefaultLocationSection from './sections/DefaultLocationSection';
import LocationNotificationsSection from './sections/LocationNotificationsSection';
import CreateGroupSection from './sections/CreateGroupSection';
import StandardButton from '../../buttons/StandardButton';
import DeleteGroupSection from './sections/DeleteGroupSection';
import { type GroupResponse } from '@/types/GroupResponse';

export default function AccountPanel(): JSX.Element {
  const [userProfile, setUserProfile] = useState<UserProfile | null>(null);
  const [defaultLocation, setDefaultLocation] = useState<UserProfile['locations'][0] | undefined>();
  const [name, setName] = useState('');
  const [group, setGroup] = useState<string[]>([]);
  const [ownGroups, setOwnGroups] = useState<GroupResponse[]>([]);

  const { setToast } = useToast();
  const { profile, selectedFranchise } = useData();
  const { signOut } = useAuth();

  // 1) Sync React state with "profile" from props/context.
  useEffect(() => {
    if (!profile) return;

    // Update local userProfile state
    setUserProfile(profile);

    // Determine default location from localStorage
    const storedDefaultLocation = localStorage.getItem('defaultLocation');
    const matchingLocation = profile.locations.find(
      (location) => location.id === storedDefaultLocation
    );

    if (matchingLocation) {
      setDefaultLocation(matchingLocation);
    } else {
      // If no default found in localStorage, you can pick the first one or leave undefined
      setDefaultLocation(profile.locations[0]);
    }
  }, [profile]);

  // 2) Fetch groups when selectedFranchise changes
  useEffect(() => {
    if (!selectedFranchise) return;

    getAllGroups(selectedFranchise.id).then((res) => { setOwnGroups(res.filter((r) => r.private)); }
    );
  }, [selectedFranchise]);

  // Handle selecting a new default location.
  function handleSelectDefaultLocation(id: string): void {
    if (!userProfile) return;

    const newDefaultLocation = userProfile.locations.find(
      (location) => location.id === id
    );
    if (newDefaultLocation) {
      localStorage.setItem('defaultLocation', newDefaultLocation.id);
      setDefaultLocation(newDefaultLocation);
    }
  }

  // Handle selecting a location to receive notifications for.
  function handleSelectLocationForNotifications(id: string): void {
    if (!userProfile) return;

    const alreadyIncluded = userProfile.notificationLocations.includes(id);
    const notificationLocations = alreadyIncluded
      ? userProfile.notificationLocations.filter((locId) => locId !== id)
      : [...userProfile.notificationLocations, id];

    // Update local state
    setUserProfile({ ...userProfile, notificationLocations });

    // Communicate with Flutter app
    console.log(
      JSON.stringify({
        [alreadyIncluded ? 'remove_notification' : 'add_notification']: id,
      })
    );
  }

  // Handle submitting new notification locations.
  async function handleSaveProfile(): Promise<void> {
    if (!userProfile) return;

    await patchUserProfile(userProfile.notificationLocations);
    setToast({
      show: true,
      message: 'Profile updated',
      type: 'success',
    });
  }

  const handleGroupChange = (locationId: string) => {
    setGroup((prev) => {
      if (prev.includes(locationId)) {
        return prev.filter((loc) => loc !== locationId);
      } else {
        return [...prev, locationId];
      }
    });
  };

  // Handle creating new group.
  async function handleNewGroup(name: string, group: string[]): Promise<void> {
    if (!userProfile || !selectedFranchise) return;

    const success = await createGroup(name, selectedFranchise.id, group);
    setToast({
      show: true,
      message: success
        ? `New group called ${name} created`
        : `Error in creating group ${name}`,
      type: success ? 'success' : 'error',
    });
  }

  // Handle Deleting a group.
  async function handleDeleteGroup(name: string): Promise<void> {
    if (!userProfile || !selectedFranchise) return;

    const success = await deleteGroup(name, selectedFranchise.id);
    setToast({
      show: true,
      message: success
        ? `Group called ${name} deleted`
        : `Error in deleting group ${name}`,
      type: success ? 'success' : 'error',
    });
  }

  return (
    <div className="grow">
      <div className="p-6 space-y-6">
        <h2 className="text-2xl text-slate-800 dark:text-slate-100 font-bold mb-5">
          My Account
        </h2>

        {/* Set Default Location */}
        {userProfile && defaultLocation && (
          <DefaultLocationSection
            userProfile={userProfile}
            selectedLocation={defaultLocation}
            handleChange={handleSelectDefaultLocation}
          />
        )}

        {/* Location Notifications */}
        {userProfile && (
          <LocationNotificationsSection
            locations={userProfile.locations}
            selectedLocations={userProfile.locations.filter((location) =>
              userProfile.notificationLocations.includes(location.id)
            )}
            handleSelect={handleSelectLocationForNotifications}
            handleSave={() => {
              handleSaveProfile().catch(console.error);
            }}
          />
        )}

        {/* Create Group */}
        {userProfile && selectedFranchise && (
          <CreateGroupSection
            locations={userProfile.locations.filter(
              (loc) => loc.franchiseId === selectedFranchise.id
            )}
            franchise={selectedFranchise}
            name={name}
            setName={setName}
            group={group}
            setGroup={setGroup}
            handleChange={handleGroupChange}
            handleCreate={(name, group) => {
              handleNewGroup(name, group).catch(console.error);
            }}
          />
        )}

        {/* Delete Group */}
        {userProfile && selectedFranchise && (
          <DeleteGroupSection
            franchise={selectedFranchise}
            groups={ownGroups}
            handleDelete={(name) => {
              handleDeleteGroup(name).catch(console.error);
            }}
          />
        )}

        {/* Sign Out */}
        <StandardButton
          onClick={() => {
            signOut().catch(console.error);
          }}
          dataCy="sign-out-button"
        >
          Sign Out
        </StandardButton>
      </div>
    </div>
  );
}
