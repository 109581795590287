import { useEffect, useRef, useState, useCallback } from "react";
import { calculateCentroidOfPolygon, calculateDistanceBetweenPoints } from "../../utils/math";
import { drawCircle, drawLine, drawPolygon, drawPolygonControls, drawText, generateRandomColor } from "../../utils/draw";
import { v4 as uuidv4 } from 'uuid';
import TooltipButton from "../TooltipButton/tooltipButton";
import { Point } from "../../interfaces/point";
import { DataArea } from "../../interfaces/dataArea";
import { Polygon } from "../../interfaces/polygon";
import { ImageData } from "../../interfaces/imageData";
import { Size } from "../../interfaces/size";
import '../../css/labelme.css';

interface ImageCanvasProps {
    imageData: ImageData | undefined;
    dataAreas: Array<DataArea>;
    editedDataArea: DataArea | undefined;
    sendDataArea: (dataAreas: Array<DataArea>) => void;
    handleCanvasSize: (canvasSize: Size<number>) => void;
    handleMode: (mode: string) => void;
    fileName: string;
}

const Canvas = (props: ImageCanvasProps) => {
    // Configuration
    const minDistanceFromPointToMergeInPx: number = 10;

    // References
    const parentRef = useRef<HTMLDivElement>(null);
    const canvasRef = useRef<HTMLCanvasElement>(null);

    // States
    const [labelId, setLabelId] = useState<string>("0");
    const [currentSize, setCurrentSize] = useState<Size<number> | undefined>(undefined);
    const [cursorPos, setCursorPos] = useState<Point | undefined>(undefined);
    const [imageData, setImageData] = useState<ImageData | undefined>(undefined);
    const [image, setImage] = useState<HTMLImageElement | null>(null);
    const [clicked, setClicked] = useState<boolean>(false);
    const [isDrawing, setIsDrawing] = useState<boolean>(false);
    const [isEditing, setIsEditing] = useState<boolean>(false);
    const [isDragging, setIsDragging] = useState<boolean>(false);
    const [draggingIndex, setDraggingIndex] = useState<number | undefined>(undefined);
    const [points, setPoints] = useState<Array<Point>>([]);
    const [pullCounter, setPullCounter] = useState<number>(1);
    const [mobileCounter, setMobileCounter] = useState<number>(1);
    const [orderboardCounter, setOrderboardCounter] = useState<number>(1);
    const [lobbyCounter, setLobbyCounter] = useState<number>(0);
    const [isBlackoutMode, setIsBlackoutMode] = useState<boolean>(false);
    const [lastFileName, setLastFileName] = useState(props.fileName);
    const [preCounter, setPreCounter] = useState<number>(0);

    if (props.fileName != lastFileName) {
        setIsBlackoutMode(false)
        setLastFileName(props.fileName)
    }

    const handleCanvasSize = () => {
        if (canvasRef.current) {
            props.handleCanvasSize({
                width: canvasRef.current.width,
                height: canvasRef.current.height,
            });
        }
    }

    const generateLabelId = () => {
        if (isBlackoutMode) {
            return "blackout";
        } else if (props.fileName === "pull" || props.fileName === "pull1") {
            return `pull_${pullCounter}`;
        } else if (props.fileName === "mobile") {
            return `mobile_${mobileCounter}`;
        } else if (props.fileName === "exit") {
            return "pickup_area";
        } else if (props.fileName === "enter") {
            if (orderboardCounter%2==1) {
                return `orderboard_area_${Math.ceil(orderboardCounter / 2)}`
            } else {
                return `preorderboard_area_${Math.ceil(orderboardCounter / 2)}`
            }
        } else if(props.fileName === "enter2") {
            if (orderboardCounter%2==1) {
                return `orderboard_area_2`
            } else {
                return `preorderboard_area_2`
            }
        } else if (props.fileName === "lobby_areas") {
            return `${lobbyCounter}`;
        } else if (props.fileName === "pre") {
            if (preCounter%2==1) {
                return 'overflow'
            } else {
                return 'pre_overflow'
            }
        } else {
            return `${labelId}`;
        }
    }

    const handleMouseDown = (e: React.MouseEvent) => {
        // Left click
        if (e.button === 0) {
            if (canvasRef.current) {
                const rect = canvasRef.current.getBoundingClientRect();
                const mouseX = e.clientX - rect.left;
                const mouseY = e.clientY - rect.top;

                const canvasSize = { width: canvasRef.current.width, height: canvasRef.current.height };

                const cursor: Point = {
                    x: mouseX, y: mouseY, scale: {
                        width: canvasRef.current.width,
                        height: canvasRef.current.height
                    }
                };

                if (!isDrawing) {
                    if (!isEditing) {
                        setIsDrawing(true);
                        props.handleMode("Drawing");
                    }
                }

                if (!clicked && !isEditing) {
                    setClicked(true);

                    let pointToSet: Point = cursor;

                    if (points.length >= 3) {
                        if (calculateDistanceBetweenPoints(cursor, points[0], canvasSize) <= minDistanceFromPointToMergeInPx) {
                            pointToSet = points[0];
                        }
                    }

                    if (pointToSet !== cursor) {
                        setIsDrawing(false);
                        props.handleMode("Waiting");

                        const polygon: Polygon = { points: points };
                        props.sendDataArea([...props.dataAreas, {
                            id: uuidv4(),
                            label: generateLabelId(),
                            color: generateRandomColor(0.5),
                            polygon: polygon
                        }]);

                        if (!isBlackoutMode) {
                            if (props.fileName === "pull" || props.fileName === "pull1") {
                                setPullCounter(pullCounter + 1);
                            } else if (props.fileName === "mobile") {
                                setMobileCounter(mobileCounter + 1);
                            } else if (props.fileName === "enter" || props.fileName === "enter2") {
                                setOrderboardCounter(orderboardCounter + 1);
                            } else if (props.fileName === "lobby_areas") {
                                setLobbyCounter(lobbyCounter + 1);
                            }  else {
                                setLabelId((prevId) => {
                                    const newId = parseInt(prevId) + 1;
                                    return newId.toString();
                                });
                            }
                        }

                        setPoints([]);
                    } else {
                        if (points.length > 0) {
                            if (calculateDistanceBetweenPoints(points[points.length - 1], pointToSet, canvasSize) > minDistanceFromPointToMergeInPx) {
                                if (points.length < 3) {
                                    if (calculateDistanceBetweenPoints(points[0], pointToSet, canvasSize) > minDistanceFromPointToMergeInPx) {
                                        setPoints([...points, pointToSet]);
                                    }
                                } else {
                                    setPoints([...points, pointToSet]);
                                }
                            }
                        } else {
                            setPoints([...points, pointToSet]);
                        }
                    }
                } else if (isEditing && !clicked) {
                    setClicked(true);
                }
            }
        } else if (e.button === 2) { // right click
            if (isDrawing) {
                if (points.length > 1) {
                    points.splice(-1);
                    setPoints([...points]);
                }
            }
        }
    };

    const handleMouseMove = (e: React.MouseEvent) => {
        if (canvasRef.current && image) {
            const rect = canvasRef.current.getBoundingClientRect();

            const endX = e.clientX - rect.left;
            const endY = e.clientY - rect.top;

            setCursorPos({
                x: endX,
                y: endY,
                scale: {
                    width: canvasRef.current.width,
                    height: canvasRef.current.height
                }
            });

            const ctx = canvasRef.current.getContext('2d');

            if (ctx) {
                drawInCanvas(ctx, canvasRef.current, image);
            }
        }
    };

    const handleMouseUp = () => {
        setClicked(false);
        setIsDragging(false);
        setDraggingIndex(undefined);
    };

    const handleEscKeyPress = useCallback((e: KeyboardEvent) => {
        if (e.key === "Escape") {
            setClicked(false);
            setIsDrawing(false);
            setPoints([]);
        }
    }, []);

    useEffect(() => {
        window.addEventListener("keydown", handleEscKeyPress);

        return () => {
            window.removeEventListener("keydown", handleEscKeyPress);
        };
    }, [handleEscKeyPress]);

    const drawInCanvas = (
        ctx: CanvasRenderingContext2D,
        canvasRef: HTMLCanvasElement,
        image: HTMLImageElement
    ) => {
        if (!imageData) return;

        const canvasSize: Size<number> = {
            width: canvasRef.width,
            height: canvasRef.height
        };
        ctx.clearRect(0, 0, canvasRef.width, canvasRef.height);
        ctx.drawImage(image, 0, 0, canvasRef.width, canvasRef.height);

        props.dataAreas.forEach((dataArea) => {
            drawPolygon(ctx, dataArea.polygon, dataArea.color, canvasRef);

            const centroid = calculateCentroidOfPolygon(
                dataArea.polygon.points,
                {
                    width: canvasRef.width,
                    height: canvasRef.height
                }
            );

            drawText(ctx, dataArea.label, centroid, 16, "Arial", "white", canvasSize);
            drawPolygonControls(ctx, dataArea.polygon, dataArea.color, canvasSize);
        });

        if (isDrawing) {
            draw(ctx, canvasRef, points);
        } else if (isEditing) {
            if (props.editedDataArea) {
                drawEditingDataArea(ctx, canvasRef, props.editedDataArea);
            }
        }
    }

    const draw = (
        ctx: CanvasRenderingContext2D,
        canvasRef: HTMLCanvasElement,
        points: Array<Point>
    ) => {
        const canvasSize: Size<number> = {
            width: canvasRef.width,
            height: canvasRef.height
        };
        points.forEach((point, idx) => {
            if (cursorPos) {
                const distanceFromCursorToPoint = calculateDistanceBetweenPoints(point, cursorPos, { width: canvasRef.width, height: canvasRef.height });
                if (idx === 0 && distanceFromCursorToPoint <= 10) {
                    drawCircle(ctx, point, "yellow", canvasSize);
                } else {
                    drawCircle(ctx, point, "red", canvasSize);
                }

                if (idx > 0 && idx < points.length) {
                    drawLine(ctx, { start: points[idx - 1], end: points[idx] }, "black", canvasSize)
                } else {
                    drawLine(ctx, { start: points[points.length - 1], end: cursorPos }, "black", canvasSize)
                }
            }
        });
    }

    const drawEditingDataArea = (
        ctx: CanvasRenderingContext2D,
        canvasRef: HTMLCanvasElement,
        dataArea: DataArea
    ) => {
        const canvasSize: Size<number> = {
            width: canvasRef.width,
            height: canvasRef.height
        };

        dataArea.polygon.points.forEach((point, idx) => {
            if (cursorPos) {
                const distanceFromCursorToPoint = calculateDistanceBetweenPoints(point, cursorPos, { width: canvasRef.width, height: canvasRef.height });
                if (distanceFromCursorToPoint <= 10 && clicked && !isDragging) {
                    setIsDragging(true);
                    setDraggingIndex(idx);
                } else if (distanceFromCursorToPoint <= 10) {
                    drawCircle(ctx, point, "yellow", canvasSize);
                } else {
                    drawCircle(ctx, point, "red", canvasSize);
                }

                if (isDragging) {
                    if (draggingIndex === idx) {
                        dataArea.polygon.points[draggingIndex] = cursorPos;
                        drawCircle(ctx, point, "yellow", canvasSize);
                    } else {
                        drawCircle(ctx, point, "red", canvasSize);
                    }
                }
            }
        });
    }

    const handleCanvasUpdate = () => {
        if (canvasRef.current && image && imageData) {
            const aspectRatio = imageData.size.height / imageData.size.width;
            const canvasHeight = canvasRef.current.width * aspectRatio;
            canvasRef.current.height = canvasHeight;

            handleCanvasSize();

            const ctx = canvasRef.current.getContext('2d');

            if (ctx) {
                drawInCanvas(ctx, canvasRef.current, image);
            }
        }
    }

    const handleWindowResize = () => {
        if (parentRef.current) {
            setCurrentSize({
                width: parentRef.current.clientWidth,
                height: parentRef.current.clientHeight
            });
        }
    };

    useEffect(() => {
        if (canvasRef.current && image && imageData) {
            const aspectRatio = imageData.size.height / imageData.size.width;
            const canvasHeight = canvasRef.current.width * aspectRatio;
            canvasRef.current.height = canvasHeight;

            const ctx = canvasRef.current.getContext('2d');

            if (ctx) {
                drawInCanvas(ctx, canvasRef.current, image);
            }
        }
    }, [points, props]);

    useEffect(() => {
        handleCanvasUpdate();
        setLabelId("0");
        setPullCounter(1);
        setMobileCounter(1);
        setOrderboardCounter(1);
        setLobbyCounter(0);
        props.sendDataArea([]);
        setPoints([]);
    }, [image]);

    useEffect(() => {
        if (imageData) {
            const img = new Image();
            img.src = imageData.urlResource;
            setImage(img);
        }
    }, [imageData]);

    useEffect(() => {
        setImageData(props.imageData);

        if (props.editedDataArea) {
            setIsDrawing(false);
            setIsEditing(true);
        } else {
            setIsEditing(false);
        }
    }, [props]);

    useEffect(() => {
        if (parentRef.current) {
            if (canvasRef.current) {
                if (currentSize) {
                    canvasRef.current.width = currentSize.width;
                }

                if (image && imageData) {
                    handleCanvasUpdate();
                }
            }
        }
    }, [currentSize]);

    useEffect(() => {
        handleWindowResize();

        window.addEventListener("resize", handleWindowResize, false);

        return () => {
            window.removeEventListener("resize", handleWindowResize);
        }
    }, []);

    return (
        <div ref={parentRef}>
            <canvas
                ref={canvasRef}
                onContextMenu={(e) => e.preventDefault()}
                onMouseDown={handleMouseDown}
                onMouseMove={handleMouseMove}
                onMouseUp={handleMouseUp}
            />
            {(props.fileName === "pull" || props.fileName === "pull1" || props.fileName === "mobile" || props.fileName === "exit" || props.fileName === "enter" || props.fileName === "enter2" || props.fileName === "lobby_areas" || props.fileName === "pre") && (
            <div className="row mb-2" style={{ display: 'flex', flexWrap: 'wrap' }}>
                <div className="d-inline-block mr-2">
                    <TooltipButton 
                        text="Set Blackout"
                        padding="10px 20px"
                        fontColor="white"
                        backgroundColor="#2e86de"
                        borderColor="#1d6fa5"
                        hoverColor="#4a90d9"
                        onClick={() => setIsBlackoutMode(true)} 
                        disabled={false}
                    /> 
                </div>
                <div className="d-inline-block">
                    <TooltipButton 
                        text="Set Increment"
                        padding="10px 20px"
                        fontColor="white"
                        backgroundColor="#2e86de"
                        borderColor="#1d6fa5"
                        hoverColor="#4a90d9"
                        onClick={() => setIsBlackoutMode(false)} 
                        disabled={false}
                    /> 
                </div>
            </div>
           
            )}
        </div>
    )
}

export default Canvas;
