// import { useState, useEffect, useRef } from 'react';
// import { NavLink, useLocation } from 'react-router-dom';
// import HellometerLogo from '../../assets/images/hellometer/logo.png';
// import HellometerLogoBoxy from '../../assets/images/hellometer/logo-boxy.png';
// import HellometerLogoSmall from '../../favicon.svg';
// import AvsLogo from '../../assets/images/avs/logo.png';
// import { useData } from '@/hooks/useData';
// import { PANDAFRANCHISE } from '@/constants';

// function Sidebar({
//   sidebarOpen,
//   setSidebarOpen,
// }: {
//   sidebarOpen: boolean;
//   setSidebarOpen: (sidebarOpen: boolean) => void;
// }): JSX.Element {
//   const location = useLocation();
//   const { pathname } = location;

//   const trigger = useRef<HTMLButtonElement>(null);
//   const sidebar = useRef<HTMLDivElement>(null);

//   const storedSidebarExpanded = localStorage.getItem('sidebar-expanded');
//   const [sidebarExpanded, setSidebarExpanded] = useState(
//     storedSidebarExpanded === null ? false : storedSidebarExpanded === 'true'
//   );

//   const { profile } = useData();
//   const isPanda = !!profile?.franchises.find(
//     (franchise) => franchise.id === PANDAFRANCHISE
//   );
//   const isHello =
//     !!profile?.franchises.some((f) => f.id === '29') &&
//     !!profile?.franchises.some((f) => f.id === '59');

//   // close on click outside
//   useEffect(() => {
//     const clickHandler = ({ target }: { target: EventTarget | null }): void => {
//       if (sidebar.current === null || trigger.current === null) return;
//       if (
//         !sidebarOpen ||
//         sidebar.current.contains(target as Node) ||
//         trigger.current.contains(target as Node)
//       ) {
//         return;
//       }
//       setSidebarOpen(false);
//     };
//     document.addEventListener('click', clickHandler);
//     return () => {
//       document.removeEventListener('click', clickHandler);
//     };
//   });

//   // close if the esc key is pressed
//   useEffect(() => {
//     const keyHandler = ({ keyCode }: { keyCode: number }): void => {
//       if (!sidebarOpen || keyCode !== 27) return;
//       setSidebarOpen(false);
//     };
//     document.addEventListener('keydown', keyHandler);
//     return () => {
//       document.removeEventListener('keydown', keyHandler);
//     };
//   });

//   useEffect(() => {
//     localStorage.setItem('sidebar-expanded', String(sidebarExpanded));
//     if (sidebarExpanded) {
//       document.querySelector('body')?.classList.add('sidebar-expanded');
//     } else {
//       document.querySelector('body')?.classList.remove('sidebar-expanded');
//     }
//   }, [sidebarExpanded]);

//   return (
//     <div className="min-w-fit" data-cy="sidebar">
//       {/* Sidebar backdrop (mobile only) */}
//       <div
//         className={`fixed inset-0 bg-slate-900 bg-opacity-30 z-40 lg:hidden lg:z-auto transition-opacity duration-200 ${
//           sidebarOpen ? 'opacity-100' : 'opacity-0 pointer-events-none'
//         }`}
//         aria-hidden="true"
//       ></div>

//       {/* Sidebar */}
//       <div
//         id="sidebar"
//         ref={sidebar}
//         className={`flex flex-col absolute z-40 left-0 top-0 lg:static lg:left-auto lg:top-auto lg:translate-x-0 h-[100dvh] overflow-y-scroll lg:overflow-y-auto no-scrollbar w-64 lg:w-20 lg:sidebar-expanded:!w-64 shrink-0 bg-slate-800 p-4 transition-all duration-200 ease-in-out ${
//           sidebarOpen ? 'translate-x-0' : '-translate-x-64'
//         }`}
//       >
//         {/* Sidebar header */}
//         <div className="flex justify-between mb-10 pr-3 sm:px-2">
//           {/* Close button */}
//           <button
//             ref={trigger}
//             className="lg:hidden text-slate-500 hover:text-slate-400"
//             onClick={() => {
//               setSidebarOpen(!sidebarOpen);
//             }}
//             aria-controls="sidebar"
//             aria-expanded={sidebarOpen}
//           >
//             <span className="sr-only">Close sidebar</span>
//             <svg
//               className="w-6 h-6 fill-current"
//               viewBox="0 0 24 24"
//               xmlns="http://www.w3.org/2000/svg"
//             >
//               <path d="M10.7 18.7l1.4-1.4L7.8 13H20v-2H7.8l4.3-4.3-1.4-1.4L4 12z" />
//             </svg>
//           </button>
//           {/* Logo */}
//           <div className="flex w-full justify-center">
//             <NavLink end to="/" className="block w-fit">
//               <img
//                 src={
//                   window.location.href.includes('avs.hellometer.io')
//                     ? AvsLogo
//                     : sidebarExpanded? HellometerLogo : HellometerLogoBoxy
//                 }
//                 className="hidden md:block"
//                 alt="Logo"
//               />
//               <img
//                 src={
//                   window.location.href.includes('avs.hellometer.io')
//                     ? ''
//                     : HellometerLogoSmall
//                 }
//                 className="block md:hidden w-8"
//                 alt="Logo"
//               />
//             </NavLink>
//           </div>
//         </div>

//         {/* Links */}
//         <div className="space-y-8">
//           {/* Pages group */}
//           <div>
//             <h3 className="text-xs uppercase text-slate-500 font-semibold pl-3">
//               <span
//                 className="hidden lg:block lg:sidebar-expanded:hidden text-center w-6"
//                 aria-hidden="true"
//               >
//                 •••
//               </span>
//               <span className="lg:hidden lg:sidebar-expanded:block">
//                 Pages
//               </span>
//             </h3>
//             <ul className="mt-3">
//               {/* Dashboard */}
//               <li
//                 className={`px-3 py-2 rounded-sm mb-0.5 last:mb-0 ${
//                   pathname === '/' && 'bg-slate-900'
//                 }`}
//               >
//                 <NavLink
//                   end
//                   to="/"
//                   className={`block text-slate-200 truncate transition duration-150 ${
//                     pathname === '/'
//                       ? 'hover:text-slate-200'
//                       : 'hover:text-white'
//                   }`}
//                 >
//                   <div className="flex items-center">
//                     <svg
//                       className="flex-shrink-0 h-6 w-6 mr-3"
//                       viewBox="0 0 25 24"
//                     >
//                       <path
//                         className={`fill-current ${
//                           pathname === '/'
//                             ? holidayColors.activeColor2
//                             : 'text-gray-400'
//                         }`}
//                         d="M12 0C5.383 0 0 5.383 0 12s5.383 12 12 12 12-5.383 12-12S18.617 0 12 0z"
//                       />
//                       <path
//                         className={`fill-current ${
//                           pathname === '/'
//                             ? 'text-green-500'
//                             : 'text-gray-600'
//                         }`}
//                         d="M12 3c-4.963 0-9 4.037-9 9s4.037 9 9 9 9-4.037 9-9-4.037-9-9-9z"
//                       />
//                       <path
//                         className={`fill-current ${
//                           pathname === '/'
//                             ? 'text-red-500'
//                             : 'text-gray-400'
//                         }`}
//                         d="M12 15c-1.654 0-3-1.346-3-3 0-.462.113-.894.3-1.285L6 6l4.714 3.301A2.973 2.973 0 0112 9c1.654 0 3 1.346 3 3s-1.346 3-3 3z"
//                       />
//                     </svg>
//                     <span className="text-sm font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
//                       Dashboard
//                     </span>
//                   </div>
//                 </NavLink>
//               </li>
//               {/* Analytics */}
//               <li className={`px-3 py-2 rounded-sm mb-0.5 last:mb-0 ${pathname === '/analyticsv2' && 'bg-slate-900'}`}>
//                 <NavLink
//                   end
//                   to="/analyticsv2"
//                   className={`block text-slate-200 truncate transition duration-150 ${
//                     pathname === '/analyticsv2' ? 'hover:text-slate-200' : 'hover:text-white'
//                   }`}
//                 >
//                   <div className="flex items-center">
//                     <svg className="flex-shrink-0 h-6 w-6 mr-3" viewBox="0 0 24 24">
//                       <path stroke="none" d="M0 0h24v24H0z" fill="none" />
//                       <rect
//                         x="10"
//                         y="9"
//                         width="4"
//                         height="12"
//                         rx="1.105"
//                         className={`fill-current ${pathname === '/analyticsv2' ? 'text-green-500' : 'text-gray-400'}`}
//                       />
//                       <rect
//                         x="17"
//                         y="3"
//                         width="4"
//                         height="18"
//                         rx="1.105"
//                         className={`fill-current ${pathname === '/analyticsv2' ? 'text-red-500' : 'text-gray-400'}`}
//                       />
//                       <circle
//                         cx="5"
//                         cy="19"
//                         r="2"
//                         className={`fill-current ${pathname === '/analyticsv2' ? holidayColors.activeColor2 : 'text-gray-400'}`}
//                       />
//                     </svg>
//                     <span className="text-sm font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
//                       Analytics
//                     </span>
//                   </div>
//                 </NavLink>
//               </li>

//               {/* Advanced Analytics */}
//               {isHello && (
//                 <li
//                   className={`px-3 py-2 rounded-sm mb-0.5 last:mb-0 ${
//                     pathname === '/advanced-analytics' && 'bg-slate-900'
//                   }`}
//                 >
//                   <NavLink
//                     end
//                     to="/advanced-analytics"
//                     className={`block text-slate-200 truncate transition duration-150 ${
//                       pathname === '/advanced-analytics'
//                         ? 'hover:text-slate-200'
//                         : 'hover:text-white'
//                     }`}
//                   >
//                     <div className="flex items-center">
//                       <svg
//                         className="flex-shrink-0 h-6 w-6 mr-3"
//                         viewBox="0 0 24 24"
//                         xmlns="http://www.w3.org/2000/svg"
//                       >
//                         {/* Magnifying Glass Circle */}
//                         <circle
//                           cx="11"
//                           cy="11"
//                           r="8"
//                           className="stroke-current text-gray-400"
//                           fill="none"
//                           strokeWidth="2"
//                         />
//                         {/* Handle */}
//                         <line
//                           x1="16.5"
//                           y1="16.5"
//                           x2="21"
//                           y2="21"
//                           className="stroke-current text-gray-400"
//                           strokeWidth="2"
//                           strokeLinecap="round"
//                         />
//                         {/* Bar Chart Inside the Glass */}
//                         <rect
//                           x="7"
//                           y="14"
//                           width="2"
//                           height="2"
//                           className={`fill-current ${
//                             pathname === '/advanced-analytics'
//                               ? holidayColors.activeColor2
//                               : 'text-gray-400'
//                           }`}
//                           rx="1"
//                         />
//                         <rect
//                           x="10"
//                           y="11"
//                           width="2"
//                           height="5"
//                           className={`fill-current ${
//                             pathname === '/advanced-analytics'
//                               ? 'text-green-500'
//                               : 'text-gray-400'
//                           }`}
//                           rx="1"
//                         />
//                         <rect
//                           x="13"
//                           y="8"
//                           width="2"
//                           height="8"
//                           className={`fill-current ${
//                             pathname === '/advanced-analytics'
//                               ? 'text-red-500'
//                               : 'text-gray-400'
//                           }`}
//                           rx="1"
//                         />
//                       </svg>
//                       <span className="text-sm font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
//                         Advanced Analytics
//                       </span>
//                     </div>
//                   </NavLink>
//                 </li>
//               )}

//               {/* Stack */}
//               {isPanda && (
//                 <li
//                   className={`px-3 py-2 rounded-sm mb-0.5 last:mb-0 ${
//                     pathname.includes('stacks') && 'bg-slate-900'
//                   }`}
//                 >
//                   <NavLink
//                     end
//                     to="/stacks"
//                     className={`block text-slate-200 truncate transition duration-150 ${
//                       pathname.includes('stacks')
//                         ? 'hover:text-slate-200'
//                         : 'hover:text-white'
//                     }`}
//                   >
//                     <div className="flex items-center">
//                       <svg
//                         className="flex-shrink-0 h-6 w-6 mr-3"
//                         viewBox="0 0 24 24"
//                       >
//                         <path stroke="none" d="M0 0h24v24H0z" fill="none" />
//                         {/* Bottom rectangle */}
//                         <rect
//                           x="4"
//                           y="16"
//                           width="16"
//                           height="4"
//                           rx="1.105"
//                           className={`fill-current ${
//                             pathname === '/stacks'
//                               ? 'text-red-500'
//                               : 'text-gray-400'
//                           }`}
//                         />
//                         {/* Middle rectangle */}
//                         <rect
//                           x="6"
//                           y="10"
//                           width="12"
//                           height="4"
//                           rx="1.105"
//                           className={`fill-current ${
//                             pathname === '/stacks'
//                               ? 'text-green-500'
//                               : 'text-gray-400'
//                           }`}
//                         />
//                         {/* Top rectangle */}
//                         <rect
//                           x="8"
//                           y="4"
//                           width="8"
//                           height="4"
//                           rx="1.105"
//                           className={`fill-current ${
//                             pathname === '/stacks'
//                               ? holidayColors.activeColor2
//                               : 'text-gray-400'
//                           }`}
//                         />
//                       </svg>
//                       <span className="text-sm font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
//                         Stacks
//                       </span>
//                     </div>
//                   </NavLink>
//                 </li>
//               )}
//               {/* Orders */}
//               <li
//                 className={`px-3 py-2 rounded-sm mb-0.5 last:mb-0 ${
//                   pathname.includes('orders') && 'bg-slate-900'
//                 }`}
//               >
//                 <NavLink
//                   end
//                   to="/orders"
//                   className={`block text-slate-200 truncate transition duration-150 ${
//                     pathname.includes('orders')
//                       ? 'hover:text-slate-200'
//                       : 'hover:text-white'
//                   }`}
//                 >
//                   <div className="flex items-center">
//                     <svg
//                       viewBox="0 0 24 24"
//                       className="flex-shrink-0 h-6 w-6 mr-3"
//                     >
//                       <path stroke="none" d="M0 0h24v24H0z" fill="none" />
//                       <path
//                         d="M9 5h-2a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-12a2 2 0 0 0 -2 -2h-2"
//                         className={`fill-current ${
//                           pathname === '/orders'
//                             ? holidayColors.activeColor2
//                             : 'text-gray-400'
//                         }`}
//                       />
//                       <rect
//                         x="9"
//                         y="3"
//                         width="6"
//                         height="4"
//                         rx="2"
//                         className={`fill-current ${
//                           pathname === '/orders'
//                             ? 'text-red-500'
//                             : 'text-gray-600'
//                         }`}
//                       />
//                       <path
//                         d="M14 11h-2.5a1.5 1.5 0 0 0 0 3h1a1.5 1.5 0 0 1 0 3h-2.5"
//                         className={`fill-current ${
//                           pathname === '/orders'
//                             ? 'text-gray-300'
//                             : 'text-gray-600'
//                         }`}
//                       />
//                       <path d="M12 17v1m0 -8v1" />
//                     </svg>
//                     <span className="text-sm font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
//                       Orders
//                     </span>
//                   </div>
//                 </NavLink>
//               </li>
//               {/* Cameras */}
//               <li
//                 className={`px-3 py-2 rounded-sm mb-0.5 last:mb-0 ${
//                   pathname.includes('cameras') && 'bg-slate-900'
//                 }`}
//               >
//                 <NavLink
//                   end
//                   to="/cameras"
//                   className={`block text-slate-200 truncate transition duration-150 ${
//                     pathname.includes('cameras')
//                       ? 'hover:text-slate-200'
//                       : 'hover:text-white'
//                   }`}
//                 >
//                   <div className="flex items-center">
//                     <svg
//                       className="flex-shrink-0 h-6 w-6 mr-3"
//                       viewBox="0 0 24 24"
//                     >
//                       <circle
//                         className={`fill-current ${
//                           pathname.includes('cameras')
//                             ? 'text-green-500'
//                             : 'text-gray-400'
//                         }`}
//                         cx="18.5"
//                         cy="5.5"
//                         r="4.5"
//                       />
//                       <circle
//                         className={`fill-current ${
//                           pathname.includes('cameras')
//                             ? holidayColors.activeColor2
//                             : 'text-gray-600'
//                         }`}
//                         cx="5.5"
//                         cy="5.5"
//                         r="4.5"
//                       />
//                       <circle
//                         className={`fill-current ${
//                           pathname.includes('cameras')
//                             ? holidayColors.activeColor2
//                             : 'text-gray-600'
//                         }`}
//                         cx="18.5"
//                         cy="18.5"
//                         r="4.5"
//                       />
//                       <circle
//                         className={`fill-current ${
//                           pathname.includes('cameras')
//                             ? 'text-red-500'
//                             : 'text-gray-400'
//                         }`}
//                         cx="5.5"
//                         cy="18.5"
//                         r="4.5"
//                       />
//                     </svg>
//                     <span className="text-sm font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
//                       Cameras
//                     </span>
//                   </div>
//                 </NavLink>
//               </li>
//               {/* Settings */}
//               <li
//                 className={`px-3 py-2 rounded-sm mb-0.5 last:mb-0 ${
//                   pathname.includes('settings') && 'bg-slate-900'
//                 }`}
//               >
//                 <NavLink
//                   end
//                   to="/settings/account"
//                   className={`block text-slate-200 truncate transition duration-150 ${
//                     pathname.includes('settings')
//                       ? 'hover:text-slate-200'
//                       : 'hover:text-white'
//                   }`}
//                 >
//                   <div className="flex items-center">
//                     <svg
//                       className="flex-shrink-0 h-6 w-6 mr-3"
//                       viewBox="0 0 24 24"
//                     >
//                       <path
//                         className={`fill-current ${
//                           pathname.includes('settings')
//                             ? holidayColors.activeColor2
//                             : 'text-gray-600'
//                         }`}
//                         d="M19.714 14.7l-7.007 7.007-1.414-1.414 7.007-7.007c-.195-.4-.298-.84-.3-1.286a3 3 0 113 3 2.969 2.969 0 01-1.286-.3z"
//                       />
//                       <path
//                         className={`fill-current ${
//                           pathname.includes('settings')
//                             ? 'text-red-500'
//                             : 'text-gray-400'
//                         }`}
//                         d="M10.714 18.3c.4-.195.84-.298 1.286-.3a3 3 0 11-3 3c.002-.446.105-.885.3-1.286l-6.007-6.007 1.414-1.414 6.007 6.007z"
//                       />
//                       <path
//                         className={`fill-current ${
//                           pathname.includes('settings')
//                             ? holidayColors.activeColor2
//                             : 'text-gray-600'
//                         }`}
//                         d="M5.7 10.714c.195.4.298.84.3 1.286a3 3 0 11-3-3c.446.002.885.105 1.286.3l7.007-7.007 1.414 1.414L5.7 10.714z"
//                       />
//                       <path
//                         className={`fill-current ${
//                           pathname.includes('settings')
//                             ? 'text-green-500'
//                             : 'text-gray-400'
//                         }`}
//                         d="M19.707 9.292a3.012 3.012 0 00-1.415 1.415L13.286 5.7c-.4.195-.84.298-1.286.3a3 3 0 113-3 2.969 2.969 0 01-.3 1.286l5.007 5.006z"
//                       />
//                     </svg>
//                     <span className="text-sm font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
//                       Settings
//                     </span>
//                   </div>
//                 </NavLink>
//               </li>
//             </ul>
//           </div>
//         </div>

//         {/* Expand / collapse button */}
//         <div className="pt-3 hidden lg:inline-flex justify-end mt-auto">
//           <div className="px-3 py-2">
//             <button
//               onClick={() => {
//                 setSidebarExpanded(!sidebarExpanded);
//               }}
//             >
//               <span className="sr-only">Expand / collapse sidebar</span>
//               <svg
//                 className="w-6 h-6 fill-current sidebar-expanded:rotate-180"
//                 viewBox="0 0 24 24"
//               >
//                 <path
//                   className="text-slate-400"
//                   d="M19.586 11l-5-5L16 4.586 23.414 12 16 19.414 14.586 18l5-5H7v-2z"
//                 />
//                 <path className="text-slate-600" d="M3 23H1V1h2z" />
//               </svg>
//             </button>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// }

// export default Sidebar;

import { useState, useEffect, useRef } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import HellometerLogo from '../../assets/images/hellometer/logo.png';
import HellometerLogoBoxy from '../../assets/images/hellometer/logo-boxy.png';
import HellometerLogoSmall from '../../favicon.svg';
import AvsLogo from '../../assets/images/avs/logo.png';
import { useData } from '@/hooks/useData';
import { PANDAFRANCHISE } from '@/constants';

function Sidebar({
  sidebarOpen,
  setSidebarOpen,
}: {
  sidebarOpen: boolean;
  setSidebarOpen: (sidebarOpen: boolean) => void;
}): JSX.Element {
  const location = useLocation();
  const { pathname } = location;

  const trigger = useRef<HTMLButtonElement>(null);
  const sidebar = useRef<HTMLDivElement>(null);

  const storedSidebarExpanded = localStorage.getItem('sidebar-expanded');
  const [sidebarExpanded, setSidebarExpanded] = useState(
    storedSidebarExpanded === null ? false : storedSidebarExpanded === 'true'
  );

  const { profile } = useData();
  const isPanda = !!profile?.franchises.find(
    (franchise) => franchise.id === PANDAFRANCHISE
  );
  const isHello =
    !!profile?.franchises.some((f) => f.id === '29') &&
    !!profile?.franchises.some((f) => f.id === '59');

  const [holidayColors, setHolidayColors] = useState({
    // activeColor1: 'text-blue-400',  // Default active color (1st shade)
    // activeColor2: 'text-blue-600',  // Default active color (2nd shade)
    // activeColor3: 'text-blue-800',  // Default active color (3rd shade)
  });

  // Check for 4th of July and 25th of December
  useEffect(() => {
    const checkHoliday = () => {
      const today = new Date();
      const month = today.getMonth();
      const date = today.getDate();

      // 4th of July - USA Holiday
      if (month === 60 && date === 40) {
        setHolidayColors({
          activeColor1: 'text-red-600', // Red (1st shade)
          activeColor2: 'text-white', // White (2nd shade)
          activeColor3: 'text-blue-600', // Blue (3rd shade)
        });
      }
      // Christmas - 25th December
      else if (month === 11 && date === 25) {
        setHolidayColors({
          activeColor1: 'text-green-600', // Green (1st shade)
          activeColor2: 'text-red-600', // Red (2nd shade)
          activeColor3: 'text-white', // White (3rd shade)
        });
      } else {
        // Default color scheme (Blue shades)
        setHolidayColors({
          activeColor1: 'text-blue-400',
          activeColor2: 'text-blue-600',
          activeColor3: 'text-blue-800',
        });
      }
    };

    checkHoliday();
  }, []); // Run once on component mount

  // close on click outside
  useEffect(() => {
    const clickHandler = ({ target }: { target: EventTarget | null }): void => {
      if (sidebar.current === null || trigger.current === null) return;
      if (
        !sidebarOpen ||
        sidebar.current.contains(target as Node) ||
        trigger.current.contains(target as Node)
      ) {
        return;
      }
      setSidebarOpen(false);
    };
    document.addEventListener('click', clickHandler);
    return () => {
      document.removeEventListener('click', clickHandler);
    };
  });

  // close if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({ keyCode }: { keyCode: number }): void => {
      if (!sidebarOpen || keyCode !== 27) return;
      setSidebarOpen(false);
    };
    document.addEventListener('keydown', keyHandler);
    return () => {
      document.removeEventListener('keydown', keyHandler);
    };
  });

  useEffect(() => {
    localStorage.setItem('sidebar-expanded', String(sidebarExpanded));
    if (sidebarExpanded) {
      document.querySelector('body')?.classList.add('sidebar-expanded');
    } else {
      document.querySelector('body')?.classList.remove('sidebar-expanded');
    }
  }, [sidebarExpanded]);

  return (
    <div className="min-w-fit" data-cy="sidebar">
      {/* Sidebar backdrop (mobile only) */}
      <div
        className={`fixed inset-0 bg-slate-900 bg-opacity-30 z-40 lg:hidden lg:z-auto transition-opacity duration-200 ${
          sidebarOpen ? 'opacity-100' : 'opacity-0 pointer-events-none'
        }`}
        aria-hidden="true"
      ></div>

      {/* Sidebar */}
      <div
        id="sidebar"
        ref={sidebar}
        className={`flex flex-col absolute z-40 left-0 top-0 lg:static lg:left-auto lg:top-auto lg:translate-x-0 h-[100dvh] overflow-y-scroll lg:overflow-y-auto no-scrollbar w-64 lg:w-20 lg:sidebar-expanded:!w-64 shrink-0 bg-slate-800 p-4 transition-all duration-200 ease-in-out ${
          sidebarOpen ? 'translate-x-0' : '-translate-x-64'
        }`}
      >
        {/* Sidebar header */}
        <div className="flex justify-between mb-10 pr-3 sm:px-2">
          {/* Close button */}
          <button
            ref={trigger}
            className="lg:hidden text-slate-500 hover:text-slate-400"
            onClick={() => {
              setSidebarOpen(!sidebarOpen);
            }}
            aria-controls="sidebar"
            aria-expanded={sidebarOpen}
          >
            <span className="sr-only">Close sidebar</span>
            <svg
              className="w-6 h-6 fill-current"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M10.7 18.7l1.4-1.4L7.8 13H20v-2H7.8l4.3-4.3-1.4-1.4L4 12z" />
            </svg>
          </button>
          {/* Logo */}
          <div className="flex w-full justify-center">
            <NavLink end to="/" className="block w-fit">
              <img
                src={
                  window.location.href.includes('avs.hellometer.io')
                    ? AvsLogo
                    : sidebarExpanded ? HellometerLogo : HellometerLogoBoxy
                }
                className="hidden md:block"
                alt="Logo"
              />
              <img
                src={
                  window.location.href.includes('avs.hellometer.io')
                    ? ''
                    : HellometerLogoSmall
                }
                className="block md:hidden w-8"
                alt="Logo"
              />
            </NavLink>
          </div>
        </div>

        {/* Links */}
        <div className="space-y-8">
          {/* Pages group */}
          <div>
            <h3 className="text-xs uppercase text-slate-500 font-semibold pl-3">
              <span
                className="hidden lg:block lg:sidebar-expanded:hidden text-center w-6"
                aria-hidden="true"
              >
                •••
              </span>
              <span className="lg:hidden lg:sidebar-expanded:block">
                Pages
              </span>
            </h3>
            <ul className="mt-3">
              {/* Dashboard */}
              <li
                className={`px-3 py-2 rounded-sm mb-0.5 last:mb-0 ${
                  pathname === '/' && 'bg-slate-900'
                }`}
              >
                <NavLink
                  end
                  to="/"
                  className={`block text-slate-200 truncate transition duration-150 ${
                    pathname === '/'
                      ? 'hover:text-slate-200'
                      : 'hover:text-white'
                  }`}
                  title={sidebarExpanded ? '' : 'Dashboard'}
                >
                  
                  <div className="flex items-center">
                    <svg
                      className="flex-shrink-0 h-6 w-6 mr-3"
                      viewBox="0 0 25 24"
                    >
                      <path
                        className={`fill-current ${
                          pathname === '/'
                            ? holidayColors.activeColor2
                            : 'text-gray-400'
                        }`}
                        d="M12 0C5.383 0 0 5.383 0 12s5.383 12 12 12 12-5.383 12-12S18.617 0 12 0z"
                      />
                      <path
                        className={`fill-current ${
                          pathname === '/'
                            ? holidayColors.activeColor3
                            : 'text-gray-600'
                        }`}
                        d="M12 3c-4.963 0-9 4.037-9 9s4.037 9 9 9 9-4.037 9-9-4.037-9-9-9z"
                      />
                      <path
                        className={`fill-current ${
                          pathname === '/'
                            ? holidayColors.activeColor1
                            : 'text-gray-400'
                        }`}
                        d="M12 15c-1.654 0-3-1.346-3-3 0-.462.113-.894.3-1.285L6 6l4.714 3.301A2.973 2.973 0 0112 9c1.654 0 3 1.346 3 3s-1.346 3-3 3z"
                      />
                    </svg>
                    <span className="text-sm font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                      Dashboard
                    </span>
                  </div>
                </NavLink>
              </li>
              {/* Analytics */}
              <li className={`px-3 py-2 rounded-sm mb-0.5 last:mb-0 ${pathname === '/analyticsv2' && 'bg-slate-900'}`}>
                <NavLink
                  end
                  to="/analyticsv2"
                  className={`block text-slate-200 truncate transition duration-150 ${
                    pathname === '/analyticsv2' ? 'hover:text-slate-200' : 'hover:text-white'
                  }`}
                  title={sidebarExpanded ? '' : 'Analytics'}
                >
                  <div className="flex items-center">
                    <svg className="flex-shrink-0 h-6 w-6 mr-3" viewBox="0 0 24 24">
                      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                      <circle
                        cx="5"
                        cy="19"
                        r="2"
                        className={`fill-current ${pathname === '/analyticsv2' ? holidayColors.activeColor2 : 'text-gray-400'}`}
                      />
                      <rect
                        x="10"
                        y="9"
                        width="4"
                        height="12"
                        rx="1.105"
                        className={`fill-current ${pathname === '/analyticsv2' ? holidayColors.activeColor1 : 'text-gray-400'}`}
                      />
                      <rect
                        x="17"
                        y="3"
                        width="4"
                        height="18"
                        rx="1.105"
                        className={`fill-current ${pathname === '/analyticsv2' ? holidayColors.activeColor3 : 'text-gray-400'}`}
                      />

                    </svg>
                    <span className="text-sm font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                      Analytics
                    </span>
                  </div>
                </NavLink>
              </li>

              {/* Advanced Analytics */}
              {isHello && (
                <li
                  className={`px-3 py-2 rounded-sm mb-0.5 last:mb-0 ${
                    pathname === '/advanced-analytics' && 'bg-slate-900'
                  }`}
                >
                  <NavLink
                    end
                    to="/advanced-analytics"
                    className={`block text-slate-200 truncate transition duration-150 ${
                      pathname === '/advanced-analytics'
                        ? 'hover:text-slate-200'
                        : 'hover:text-white'
                    }`}
                    title={sidebarExpanded ? '' : 'Advanced Analytics'}
                  >
                    <div className="flex items-center">
                      <svg
                        className="flex-shrink-0 h-6 w-6 mr-3"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        {/* Magnifying Glass Circle */}
                        <circle
                          cx="11"
                          cy="11"
                          r="8"
                          className={`stroke-current ${pathname === '/advanced-analytics' ? holidayColors.activeColor1 : 'text-gray-400'}`}
                          fill="none"
                          strokeWidth="2"
                        />
                        {/* Handle */}
                        <line
                          x1="16.5"
                          y1="16.5"
                          x2="21"
                          y2="21"
                          className={`stroke-current ${pathname === '/advanced-analytics' ? holidayColors.activeColor1 : 'text-gray-400'}`}
                          strokeWidth="2"
                          strokeLinecap="round"
                        />
                        {/* Bar Chart Inside the Glass */}
                        <rect
                          x="7"
                          y="14"
                          width="2"
                          height="2"
                          className={`fill-current ${
                            pathname === '/advanced-analytics'
                              ? 'text-blue-500'
                              : 'text-gray-400'
                          }`}
                          rx="1"
                        />
                        <rect
                          x="10"
                          y="11"
                          width="2"
                          height="5"
                          className={`fill-current ${
                            pathname === '/advanced-analytics'
                              ? 'text-green-500'
                              : 'text-gray-400'
                          }`}
                          rx="1"
                        />
                        <rect
                          x="13"
                          y="8"
                          width="2"
                          height="8"
                          className={`fill-current ${
                            pathname === '/advanced-analytics'
                              ? 'text-red-500'
                              : 'text-gray-400'
                          }`}
                          rx="1"
                        />
                      </svg>
                      <span className="text-sm font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                        Advanced Analytics
                      </span>
                    </div>
                  </NavLink>
                </li>
              )}

              {/* Stack */}
              {isPanda && (
                <li
                  className={`px-3 py-2 rounded-sm mb-0.5 last:mb-0 ${
                    pathname.includes('stacks') && 'bg-slate-900'
                  }`}
                >
                  <NavLink
                    end
                    to="/stacks"
                    className={`block text-slate-200 truncate transition duration-150 ${
                      pathname.includes('stacks')
                        ? 'hover:text-slate-200'
                        : 'hover:text-white'
                    }`}
                    title={sidebarExpanded ? '' : 'Stacks'}
                  >
                    <div className="flex items-center">
                      <svg
                        className="flex-shrink-0 h-6 w-6 mr-3"
                        viewBox="0 0 24 24"
                      >
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        {/* Top rectangle */}
                        <rect
                          x="8"
                          y="4"
                          width="8"
                          height="4"
                          rx="1.105"
                          className={`fill-current ${
                            pathname === '/stacks'
                              ? holidayColors.activeColor2
                              : 'text-gray-400'
                          }`}
                        />
                        {/* Middle rectangle */}
                        <rect
                          x="6"
                          y="10"
                          width="12"
                          height="4"
                          rx="1.105"
                          className={`fill-current ${
                            pathname === '/stacks'
                              ? holidayColors.activeColor1
                              : 'text-gray-400'
                          }`}
                        />
                        {/* Bottom rectangle */}
                        <rect
                          x="4"
                          y="16"
                          width="16"
                          height="4"
                          rx="1.105"
                          className={`fill-current ${
                            pathname === '/stacks'
                              ? holidayColors.activeColor3
                              : 'text-gray-400'
                          }`}
                        />
                      </svg>
                      <span className="text-sm font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                        Stacks
                      </span>
                    </div>
                  </NavLink>
                </li>
              )}
              {/* Orders */}
              <li
                className={`px-3 py-2 rounded-sm mb-0.5 last:mb-0 ${
                  pathname.includes('orders') && 'bg-slate-900'
                }`}
              >
                <NavLink
                  end
                  to="/orders"
                  className={`block text-slate-200 truncate transition duration-150 ${
                    pathname.includes('orders')
                      ? 'hover:text-slate-200'
                      : 'hover:text-white'
                  }`}
                  title={sidebarExpanded ? '' : 'Orders'}
                >
                  <div className="flex items-center">
                    <svg
                      viewBox="0 0 24 24"
                      className="flex-shrink-0 h-6 w-6 mr-3"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                      <path
                        d="M9 5h-2a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-12a2 2 0 0 0 -2 -2h-2"
                        className={`fill-current ${
                          pathname === '/orders'
                            ? holidayColors.activeColor1
                            : 'text-gray-400'
                        }`}
                      />
                      <rect
                        x="9"
                        y="3"
                        width="6"
                        height="4"
                        rx="2"
                        className={`fill-current ${
                          pathname === '/orders'
                            ? holidayColors.activeColor2
                            : 'text-gray-600'
                        }`}
                      />
                      <path
                        d="M14 11h-2.5a1.5 1.5 0 0 0 0 3h1a1.5 1.5 0 0 1 0 3h-2.5"
                        className={`fill-current ${
                          pathname === '/orders'
                            ? holidayColors.activeColor3
                            : 'text-gray-600'
                        }`}
                      />
                      <path d="M12 17v1m0 -8v1" />
                    </svg>
                    <span className="text-sm font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                      Orders
                    </span>
                  </div>
                </NavLink>
              </li>
              {/* Cameras */}
              <li
                className={`px-3 py-2 rounded-sm mb-0.5 last:mb-0 ${
                  pathname.includes('cameras') && 'bg-slate-900'
                }`}
              >
                <NavLink
                  end
                  to="/cameras"
                  className={`block text-slate-200 truncate transition duration-150 ${
                    pathname.includes('cameras')
                      ? 'hover:text-slate-200'
                      : 'hover:text-white'
                  }`}
                  title={sidebarExpanded ? '' : 'Cameras'}
                >
                  <div className="flex items-center">
                    <svg
                      className="flex-shrink-0 h-6 w-6 mr-3"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        className={`fill-current ${
                          pathname.includes('cameras')
                            ? holidayColors.activeColor1
                            : 'text-gray-400'
                        }`}
                        cx="18.5"
                        cy="5.5"
                        r="4.5"
                      />
                      <circle
                        className={`fill-current ${
                          pathname.includes('cameras')
                            ? holidayColors.activeColor2
                            : 'text-gray-600'
                        }`}
                        cx="5.5"
                        cy="5.5"
                        r="4.5"
                      />
                      <circle
                        className={`fill-current ${
                          pathname.includes('cameras')
                            ? holidayColors.activeColor2
                            : 'text-gray-600'
                        }`}
                        cx="18.5"
                        cy="18.5"
                        r="4.5"
                      />
                      <circle
                        className={`fill-current ${
                          pathname.includes('cameras')
                            ? holidayColors.activeColor1
                            : 'text-gray-400'
                        }`}
                        cx="5.5"
                        cy="18.5"
                        r="4.5"
                      />
                    </svg>
                    <span className="text-sm font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                      Cameras
                    </span>
                  </div>
                </NavLink>
              </li>
              {/* Settings */}
              <li
                className={`px-3 py-2 rounded-sm mb-0.5 last:mb-0 ${
                  pathname.includes('settings') && 'bg-slate-900'
                }`}
              >
                <NavLink
                  end
                  to="/settings/account"
                  className={`block text-slate-200 truncate transition duration-150 ${
                    pathname.includes('settings')
                      ? 'hover:text-slate-200'
                      : 'hover:text-white'
                  }`}
                  title={sidebarExpanded ? '' : 'Settings'}
                >
                  <div className="flex items-center">
                    <svg
                      className="flex-shrink-0 h-6 w-6 mr-3"
                      viewBox="0 0 25 25"
                    >
                      <path
                        className={`fill-current ${
                          pathname.includes('settings')
                            ? holidayColors.activeColor2
                            : 'text-gray-600'
                        }`}
                        d="M19.714 14.7l-7.007 7.007-1.414-1.414 7.007-7.007c-.195-.4-.298-.84-.3-1.286a3 3 0 113 3 2.969 2.969 0 01-1.286-.3z"
                      />
                      <path
                        className={`fill-current ${
                          pathname.includes('settings')
                            ? holidayColors.activeColor1
                            : 'text-gray-400'
                        }`}
                        d="M10.714 18.3c.4-.195.84-.298 1.286-.3a3 3 0 11-3 3c.002-.446.105-.885.3-1.286l-6.007-6.007 1.414-1.414 6.007 6.007z"
                      />
                      <path
                        className={`fill-current ${
                          pathname.includes('settings')
                            ? holidayColors.activeColor2
                            : 'text-gray-600'
                        }`}
                        d="M5.7 10.714c.195.4.298.84.3 1.286a3 3 0 11-3-3c.446.002.885.105 1.286.3l7.007-7.007 1.414 1.414L5.7 10.714z"
                      />
                      <path
                        className={`fill-current ${
                          pathname.includes('settings')
                            ? holidayColors.activeColor1
                            : 'text-gray-400'
                        }`}
                        d="M19.707 9.292a3.012 3.012 0 00-1.415 1.415L13.286 5.7c-.4.195-.84.298-1.286.3a3 3 0 113-3 2.969 2.969 0 01-.3 1.286l5.007 5.006z"
                      />
                    </svg>
                    <span className="text-sm font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                      Settings
                    </span>
                  </div>
                </NavLink>
              </li>
            </ul>
          </div>
        </div>

        {/* Expand / collapse button */}
        <div className="pt-3 hidden lg:inline-flex justify-end mt-auto">
          <div className="px-3 py-2">
            <button
              onClick={() => {
                setSidebarExpanded(!sidebarExpanded);
              }}
            >
              <span className="sr-only">Expand / collapse sidebar</span>
              <svg
                className="w-6 h-6 fill-current sidebar-expanded:rotate-180"
                viewBox="0 0 24 24"
              >
                <path
                  className="text-slate-400"
                  d="M19.586 11l-5-5L16 4.586 23.414 12 16 19.414 14.586 18l5-5H7v-2z"
                />
                <path className="text-slate-600" d="M3 23H1V1h2z" />
              </svg>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Sidebar;
