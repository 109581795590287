import type { Detection } from '../types/Detection';
import type { Column } from '../types/Column';
import { getRegionOrder } from './tables';

export interface RowType {
  arrivalTime: string;
  [regionKey: string]: number | string;
  modelId: number;
  totalTime: number;
  validTimeSpent: number;
}

// Get the rows from the data.
export function getRows(rawData: Detection[]): RowType[] {
  if (rawData.length === 0) {
    return [];
  }
  const rows: RowType[] = [];
  for (const detection of rawData) {
    const row: RowType = {
      arrivalTime: detection.arrivalTime,
      modelId: detection.modelId,
      totalTime: 0,
      validTimeSpent: 0
    };
    for (const region of detection.regions) {
      if (region.timeSpent === undefined) {
        region.timeSpent = 0;
      }
      row[region.key] = region.timeSpent;
    }
    row.totalTime = detection.timeSpent;
    row.validTimeSpent = detection.validTimeSpent;
    rows.push(row);
  }
  return rows;
}


const converter = { '0': 'line', '1': 'counter' };

// Get the columns from the data.
export function getColumns(rawData: Detection[]): Column[] {
  if (rawData.length === 0) {
    return [];
  }
  const regions: Record<string, string> = {};
  let cols = [];
  // Get all possible regions.
  for (const data of rawData) {
    for (const region of data.regions) {
      if (region.timeSpent) {
        regions[region.key] = region.displayName;
      }
    }
  }
  for (const key in regions) {
    cols.push({
      label: converter.hasOwnProperty(regions[key]) ? converter[regions[key]] : regions[key],
      type: 'seconds',
      key,
    });
  }
  // sort columns
  cols = getRegionOrder(cols);
  // set first and last columns
  cols = [
    {
      label: 'Arrival Time',
      key: 'arrivalTime',
      type: 'date',
    },
    ...cols,
    {
      label: 'Total Time',
      key: 'totalTime',
      type: 'seconds',
    },
  ];
  return cols;
}
