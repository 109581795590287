/**
 * Presentational component for the table body.
 */

import TableValue from './TableValue';
import type { TableColumn } from '../../types/TableColumn';
import type { BestWorstValues } from '../../types/BestWorstValues';
import type { Location } from '../../types/Location';
import React from 'react';
import { presentationFormat } from '@/lib/utils';

export default function TableBodyOrders({
  data,
  columns,
  location,
  bestValues,
  worstValues,
  expandedRows,
  toggleRow,
}: {
  data: object[];
  columns: TableColumn[];
  location?: Location;
  bestValues?: BestWorstValues;
  worstValues?: BestWorstValues;
  expandedRows: Record<number, boolean>;
  toggleRow: (orderId: number) => void;
}): JSX.Element {

  return (
    <tbody className="divide-y divide-gray-200 dark:divide-slate-700 text-xs bg-white dark:bg-[#182235] border-slate-200 dark:border-slate-700">
  {data.map((row, i) => {
    const isExpanded = expandedRows[row.orderId as number] || false;
    const hasFlags = row.flags ? true : false;

    return (
      <React.Fragment key={i}>
        {/* Main Row */}
        <tr
          data-cy={`row-${i}`}
          className={`${
            row.flags ? 'text-black bg-orange-400 hover:bg-orange-500' : 'hover:bg-gray-100 dark:hover:bg-slate-800'
          } cursor-pointer relative group`}
        >
          {columns.map((col) => {
            const value = row[col.key as keyof typeof row];
            let href = col.href;

            if (href !== undefined) {
              href = href.replace(
                'locationId',
                row['locationId' as keyof object] as string
              );
            }

            return (
              <TableValue
                key={col.key}
                value={value}
                column={col}
                location={location}
                locationId={row['locationId' as keyof object] as string}
                isBestValue={
                  bestValues !== undefined && bestValues[col.key] === value
                }
                isWorstValue={
                  worstValues !== undefined && worstValues[col.key] === value
                }
                href={href}
              />
            );
          })}
          {/* Expand Button */}
          <td className="text-center">
            <button
              onClick={() => { toggleRow(row.orderId as number); }}
              className="text-blue-500 hover:underline"
              >
              {isExpanded ? 'Collapse Items' : 'Expand Items'}
            </button>
            {hasFlags && (
              <span
              className="absolute left-1/2 transform -translate-x-1/2 hidden group-hover:block bg-gray-700 text-white rounded px-2 py-1 text-sm"
              style={{ top: '100%', marginTop: '5px', zIndex: 10 }}
            >
              Flags: {row.flags.map(f => presentationFormat(f)).join(", ")}
            </span>
            )}
          </td>
        </tr>


        {/* Expanded Row */}
        {isExpanded && (
          <tr className="bg-gray-50 dark:bg-slate-700">
            <td colSpan={columns.length + 1}>
              <div className="p-4 border border-s-200 dark:border-slate-600 rounded-md shadow-sm bg-white dark:bg-[#182235]">
                <h3 className="font-semibold mb-2 text-gray-700 dark:text-gray-300 ">
                  Order Items
                </h3>
                {row.items && row.items.length > 0
? (
                  <table className="dark:bg-[#182235] w-full text-left border-collapse border border-gray-300 dark:border-slate-600">
                    <thead className="bg-gray-100 dark:bg-slate-700 text-gray-600 dark:text-gray-300">
                      <tr>
                        <th className="px-4 py-2 border border-gray-300 dark:border-slate-600 dark:bg-[#182235]">
                        {/* className="divide-y divide-gray-200 dark:divide-slate-700 text-xs bg-white dark:bg-[#182235] border-slate-200 dark:border-slate-700" */}
                          Description
                        </th>
                        <th className="px-4 py-2 border border-gray-300 dark:border-slate-600 dark:bg-[#182235]">
                          Price
                        </th>
                        <th className="px-4 py-2 border border-gray-300 dark:border-slate-600 dark:bg-[#182235]">
                          Display Price
                        </th>
                        <th className="px-4 py-2 border border-gray-300 dark:border-slate-600 dark:bg-[#182235]">
                          Gross Sales
                        </th>
                        <th className="px-4 py-2 border border-gray-300 dark:border-slate-600 dark:bg-[#182235]">
                          Net Sales
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {row.items.map((item: OrderItem, index: number) => (
                        <tr
                          key={`${item.description}-${index}`}
                        >
                          <td className="px-4 py-2 border border-gray-300 dark:border-slate-600">
                            {item.description}
                          </td>
                          <td className="px-4 py-2 border border-gray-300 dark:border-slate-600">
                            ${item.price.toFixed(2)}
                          </td>
                          <td className="px-4 py-2 border border-gray-300 dark:border-slate-600">
                            ${item.displayPrice.toFixed(2)}
                          </td>
                          <td className="px-4 py-2 border border-gray-300 dark:border-slate-600">
                            ${item.grossSales.toFixed(2)}
                          </td>
                          <td className="px-4 py-2 border border-gray-300 dark:border-slate-600">
                            ${item.netSales.toFixed(2)}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                )
: (
                  <p className="text-gray-500 dark:text-gray-400">
                    No items available.
                  </p>
                )}
              </div>
            </td>
          </tr>
        )}
      </React.Fragment>
    );
  })}
</tbody>

  );
}
