/**
 * Main Dashboard Page
 * This page is intended to allow the customer to view
 * a single day's worth of data from any of their locations.
 */
import { useEffect, useState, Fragment } from 'react';
import PageLayout from '../../components/ui/PageLayout';
import DropdownFranchise from '../../components/dropdowns/DropdownFranchise';
import DropdownLocationMultiple from '../../components/dropdowns/DropdownLocationMultiple';
import Datepicker from '../../components/time/Datepicker';
import DriveThruLobbyToggle from '../../components/buttons/DriveThruLobbyToggle';
import DayPartsTable from '../../components/tables/DayPartsTable';
import RanksTable from '../../components/tables/RanksTable';
import StationsTable from '../../components/tables/StationsTable';
import DetectionTable from '../../components/tables/DetectionsTable';
import { useData } from '../../hooks/useData';
import ModalBasic from '@/components/modals/ModalBasic';
import GroupModal from '@/components/modals/GroupModal';
import { getAllGroups, getGroupLocationsByName } from '@/utils/api';
import { type GroupResponse } from '@/types/GroupResponse';
import GroupsButton from '@/components/buttons/GroupsButton';


function Dashboard(): JSX.Element {
  const {
    selectedDates,
    setSelectedDates,
    selectedFranchise,
    handleSelectGroup,
  } = useData();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [groupOptions, setGroupOptions] = useState<GroupResponse[]>([]);
  const [selectedGroup, setSelectedGroup] = useState<GroupResponse | null>(null);

  // Set dates on load.
  useEffect(() => {
    const today = new Date();
    setSelectedDates([today, today]);
  }, []);

  useEffect(() => {
    const franchiseId = selectedFranchise?.id || '';
    if (!selectedGroup || !franchiseId) {
      return;
    }
    // Fetch group locations
    getGroupLocationsByName(selectedGroup, franchiseId)
    .then((groupLocations) => { handleSelectGroup(groupLocations, ''); });
  }, [selectedGroup]);

  useEffect(() => {
    const franchiseId = selectedFranchise?.id || '';
    if (!franchiseId || !selectedFranchise) {
      return;
    }
    getAllGroups(franchiseId)
    .then((res) => { setGroupOptions(res); });
  }, [selectedFranchise]);

  return (
    <PageLayout>
      <div data-cy="dashboard">
        {/* Dashboard actions */}
        <div className="sm:flex sm:justify-between sm:items-center mb-8 gap-4">
          {/* Left: Franchise and Location Selector */}
          <div className="flex flex-wrap justify-start gap-2 mb-4">
            {/* Franchise Selector */}
            <DropdownFranchise />
            {/* Location Selector */}
            <DropdownLocationMultiple dataCy="dashboard-location-selector" />
            {/* Group Filters -- hide if no group options */}
            {groupOptions.length === 0
            ? <Fragment></Fragment>
            : (
            <Fragment>
              <GroupsButton onClick={() => setIsOpen(prev => !prev)} />
              <div hidden={true}>
                <ModalBasic
                  title='Groups'
                  isOpen={isOpen}
                  setIsOpen={setIsOpen}
                  dataCy='modal-filter-groups'
                >
                  <GroupModal
                    groupOptions={groupOptions}
                    selectedGroup={selectedGroup}
                    setSelectedGroup={setSelectedGroup}
                  />
                </ModalBasic>
              </div>
            </Fragment>)}
          </div>
          {/* Right: Actions */}
          <div className="grid grid-flow-col sm:auto-cols-max justify-start sm:justify-end gap-2">
            {/* Datepicker built with flatpickr */}
            <Datepicker
              initDates={selectedDates}
              handleDateChange={setSelectedDates}
              mode="single"
            />
            {/* Toggles for lobby and drive-thru */}
            <DriveThruLobbyToggle />
          </div>
        </div>
        {/* Tables */}
        <div className="flex flex-col gap-4">
          <RanksTable />
          <DayPartsTable />
          <StationsTable />
          <DetectionTable />
        </div>
      </div>
    </PageLayout>
  );
}

export default Dashboard;
