/**
 * Presentational component for Groups button.
 */

export default function GroupsButton({
  onClick,
}: {
  onClick: () => void;
}): JSX.Element {
  return (
    <button
      className="btn border-gray-200 dark:border-slate-700 bg-white dark:bg-slate-800 hover:border-gray-300 text-slate-500 dark:text-slate-400 w-6/12 sm:w-auto"
      onClick={onClick}
    >
      <span className="ml-0">Groups</span>
    </button>
  );
}
