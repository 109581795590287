import { CardHeader, CardTitle } from '@/components/ui/card';

function CardHeaderGrid({
    title,
    expand,
    setExpand,
  }: {
    title: string
    expand: boolean,
    setExpand: React.Dispatch<React.SetStateAction<boolean>>;
  }): JSX.Element {

    return (
      <CardHeader className='flex flex-row justify-between items-center'>
        <CardTitle>{title}</CardTitle>
        <button
        onClick={(() => setExpand(!expand))}
        className={`relative cursor-pointer transition-all duration-300 ease-in-out
            hover:transition-all hover:duration-300
            ${expand ? 'scale-125 hover:scale-75' : 'scale-75 hover:scale-125'}`}
        >
        <div
            className={`w-10 h-10
            transition-all duration-300 ease-in-out
            flex justify-center items-center`}
        >🔎</div>
        </button>
      </CardHeader>
      )
  }

export default CardHeaderGrid;
