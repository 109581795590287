import React, { useState, useMemo } from 'react';
import { Roles } from '../../../types/Roles';
import { type FranchiseUser } from '../../../types/FranchiseUser';
import AccordionBasic from '../../accordions/AccordionBasic';
import DropdownFilter, { type OptionType } from '../../dropdowns/DropdownFilter';
import { type FranchiseLocation } from '../../../types/FranchiseLocation';
import ModalBasic from '../../modals/ModalBasic';
import { useAuth } from '../../../hooks/useAuth';

interface UserItemProps {
  currentUser: FranchiseUser;
  franchiseLocations: FranchiseLocation[];
  onSave: (user: FranchiseUser) => void;
  onDelete: (user: FranchiseUser) => void;
}

const UserItem: React.FC<UserItemProps> = ({
  currentUser,
  franchiseLocations,
  onSave,
  onDelete,
}) => {
  const [editUser, setEditUser] = useState<FranchiseUser>({ ...currentUser });
  const [isOpen, setIsOpen] = useState(false);
  const { user } = useAuth();

  const userRoles = [
    { id: Roles.ADMIN, value: Roles.ADMIN },
    { id: Roles.MANAGER, value: Roles.MANAGER },
    { id: Roles.MEMBER, value: Roles.MEMBER },
  ];

  const handleSave = () => {
    onSave(editUser);
  };

  const handleDiscardChanges = () => {
    setEditUser(currentUser);
  };

  const handleDelete = () => {
    onDelete(editUser);
  };

  // ------------------------------
  // Location permissions changes
  // ------------------------------
  function handleSelectedLocationsChange(id: string): void {
    // Make a new copy of the outer map
    const newLocationPermissions = new Map(editUser.locationPermissions);

    // If this location is already present, remove it
    if (newLocationPermissions.has(id)) {
      newLocationPermissions.delete(id);
    } else {
      // Otherwise, add a new entry with videoAccess = true
      newLocationPermissions.set(
        id,
        new Map([['videoAccess', true]])
      );
    }

    setEditUser({
      ...editUser,
      locationPermissions: newLocationPermissions,
    });
  }

  function handleSelectAllLocations(): void {
    const newLocationPermissions = new Map(editUser.locationPermissions);
    franchiseLocations.forEach((loc) => {
      if (!newLocationPermissions.has(loc.locationId)) {
        newLocationPermissions.set(
          loc.locationId,
          new Map([['videoAccess', true]])
        );
      }
    });
    setEditUser({
      ...editUser,
      locationPermissions: newLocationPermissions,
    });
  }

  function handleDeselectAllLocations(): void {
    const newLocationPermissions = new Map(editUser.locationPermissions);
    franchiseLocations.forEach((loc) => {
      if (newLocationPermissions.has(loc.locationId)) {
        newLocationPermissions.delete(loc.locationId);
      }
    });
    setEditUser({
      ...editUser,
      locationPermissions: newLocationPermissions,
    });
  }

  // ------------------------------
  // Toggling videoAccess
  // ------------------------------
  function handleVideoAccessChange(id: string, checked: boolean): void {
    // Retrieve the inner map for this location
    const oldInnerMap = editUser.locationPermissions.get(id);
    if (!oldInnerMap) return;

    // Clone the inner map so changes are tracked
    const newInnerMap = new Map(oldInnerMap);
    newInnerMap.set('videoAccess', checked);

    // Clone the outer map and set this updated inner map
    const newLocationPermissions = new Map(editUser.locationPermissions);
    newLocationPermissions.set(id, newInnerMap);

    setEditUser({
      ...editUser,
      locationPermissions: newLocationPermissions,
    });
  }

  // -----------------------------------------
  // Compare locationPermissions deeply
  // -----------------------------------------
  function areLocationPermissionsEqual(
    permsA: Map<string, Map<string, boolean>>,
    permsB: Map<string, Map<string, boolean>>
  ): boolean {
    if (permsA.size !== permsB.size) return false;
    for (const [locationId, permsMapA] of permsA) {
      const permsMapB = permsB.get(locationId);
      if (!permsMapB) return false;

      // Compare each key in the inner map
      for (const [key, valA] of permsMapA) {
        const valB = permsMapB.get(key);
        if (valA !== valB) return false;
      }
      // Also ensure no extra keys exist in permsMapB
      if (permsMapB.size !== permsMapA.size) return false;
    }
    return true;
  }

  // -----------------------------------------
  // Determine if the user has been modified
  // -----------------------------------------
  const isUserModified = useMemo(() => {
    const roleChanged = editUser.userRole !== currentUser.userRole;
    const locationsChanged = !areLocationPermissionsEqual(
      editUser.locationPermissions,
      currentUser.locationPermissions
    );
    return roleChanged || locationsChanged;
  }, [editUser, currentUser]);

  // The Save button is enabled only if something changed
  const isSaveButtonEnabled = isUserModified;
  const isDiscardButtonEnabled = isUserModified;

  return (
    <AccordionBasic title={editUser.email} dataCy="location-accordion">
      {/* First row: Role & Locations */}
      <div className="flex flex-wrap w-full gap-4">
        {/* Role */}
        <div className="flex flex-col">
          <label>Role</label>
          <DropdownFilter
            title={editUser.userRole}
            options={userRoles}
            selected={[
              { id: editUser.userRole, value: editUser.userRole },
            ]}
            handleChange={(id) => {
              setEditUser({
                ...editUser,
                userRole: userRoles.find((val) => val.id === id)?.value!,
              });
            }}
            dataCy={'dropdown-region-multiple'}
          />
        </div>

        {/* Locations & Video Access */}
        <div className="flex flex-col">
          <label>Locations and Video Access</label>
          <DropdownFilter
            title={`${
              franchiseLocations.filter((loc) =>
                editUser.locationPermissions.has(loc.locationId)
              ).length
            } Locations`}
            options={
              franchiseLocations.map((loc) => ({
                id: loc.locationId,
                value: loc.displayName,
                videoAccess: editUser.locationPermissions
                  .get(loc.locationId)
                  ?.get('videoAccess'),
              })) as OptionType[]
            }
            selected={
              franchiseLocations
                .filter((loc) =>
                  editUser.locationPermissions.has(loc.locationId)
                )
                .map((loc) => ({
                  id: loc.locationId,
                  value: loc.displayName,
                  videoAccess: editUser.locationPermissions
                    .get(loc.locationId)
                    ?.get('videoAccess'),
                })) as OptionType[]
            }
            handleChange={handleSelectedLocationsChange}
            handleVideoAccessChange={handleVideoAccessChange}
            dataCy={'dropdown-region-multiple'}
          />
        </div>
      </div>

      {/* Second row: Buttons on the right side */}
      <div className="flex flex-wrap w-full gap-4 mt-4 justify-end">
        {/* Save Button */}
        <div className="flex flex-col">
          <button
            className={`btn ${
              isSaveButtonEnabled
                ? 'border-slate-200 dark:border-slate-700 hover:border-slate-300 dark:hover:border-slate-600 shadow-sm text-indigo-500'
                : 'text-gray-500 border-gray-200 cursor-not-allowed'
            }`}
            onClick={handleSave}
            disabled={!isSaveButtonEnabled}
            data-cy="save-user-button"
          >
            Save
          </button>
        </div>

        {/* Discard Button */}
        <div className="flex flex-col">
          <button
            className={`btn ${
              isDiscardButtonEnabled
              ? 'bg-orange-500 hover:bg-orange-600 text-white'
              : 'text-gray-500 border-gray-200 cursor-not-allowed'
            }`}
            onClick={handleDiscardChanges}
            disabled={!isDiscardButtonEnabled}
            data-cy="save-user-button"
          >
            Discard Changes
          </button>
        </div>

        {/* Delete Button (hidden if user is themselves) */}
        {user?.attributes.email !== currentUser.email && (
          <div className="flex flex-col">
            <button
              className="btn bg-rose-500 hover:bg-rose-600 text-white"
              onClick={() => {
                setIsOpen(true);
              }}
              data-cy="delete-user-button"
            >
              Delete
            </button>
          </div>
        )}
      </div>

      {/* Confirmation Modal for Deletion */}
      <div hidden={true}>
        <ModalBasic
          title="Delete User"
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          dataCy="modal-delete-user"
        >
          <div className="flex flex-col gap-4 min-w-96 p-4">
            <h3>Are you sure you want to delete {editUser.email}?</h3>
            <div className="flex flex-row justify-end">
              <div className="pr-1">
                <button
                  className="btn bg-rose-500 hover:bg-rose-600 text-white"
                  onClick={handleDelete}
                  data-cy="save-user-button"
                >
                  Yes
                </button>
              </div>
              <div className="pl-1">
                <button
                  className="btn border-slate-200 dark:border-slate-700 hover:border-slate-300 dark:hover:border-slate-600 shadow-sm text-indigo-500"
                  onClick={() => {
                    setIsOpen(false);
                  }}
                  data-cy="delete-user-button"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </ModalBasic>
      </div>
    </AccordionBasic>
  );
};

export default UserItem;
